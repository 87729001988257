import Keycloak, { KeycloakConfig } from "keycloak-js";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { KEYCLOAK_CREDENTIALS, REFRESH_TOKEN_MIN_VALIDITY, REFRESH_TOKEN_UPDATE_TIME } from "./constants/keycloak";
import { nextKeycloakInitError, setCurrentUserToken } from "./hooks/auth";
import { getUserMe, userMeGlobalState } from "./hooks/user";
import KeycloakLoading from "./components/KeycloakLoading/KeycloakLoading";
import { LINK_LOGOUT } from "./constants/navigate";
import i18n from "./i18n";

const KeycloakContext = createContext<{
  keycloak: Keycloak | undefined; // NOSONAR
  initialized: boolean;
}>(undefined as any);

interface IKeycloakProvider {
  children: React.ReactNode;
}

const keycloakConfig: KeycloakConfig = {
  url: KEYCLOAK_CREDENTIALS.URL,
  realm: KEYCLOAK_CREDENTIALS.REALM,
  clientId: KEYCLOAK_CREDENTIALS.CLIENT_ID
};

const keycloak = new Keycloak(keycloakConfig);

const updateToken = () => {
  keycloak.updateToken(REFRESH_TOKEN_MIN_VALIDITY).then(() => {
    if (keycloak.authenticated) {
      setCurrentUserToken(keycloak.token, keycloak.refreshToken, keycloak.idToken, keycloak.tokenParsed);
      setRefreshTokenTimeout(Math.min(keycloak.refreshTokenParsed.exp, keycloak.tokenParsed.exp));
    }
  }).catch(console.error);
};

let refreshTokenTimeout: any = null;

const setRefreshTokenTimeout = (exp: number) => {
  const now = new Date().getTime();
  const expires = exp * 1000;
  const delay = expires - now - REFRESH_TOKEN_UPDATE_TIME;

  if (refreshTokenTimeout) {
    clearTimeout(refreshTokenTimeout);
  }
  if (delay > 0) {
    refreshTokenTimeout = setTimeout(updateToken, delay);
  } else {
    updateToken();
  }
};

export const KeycloakProvider: React.FunctionComponent<IKeycloakProvider> = (props: IKeycloakProvider) => {
  const didLogRef = useRef(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!didLogRef.current) {
      didLogRef.current = true;
      keycloak.init({
        token: sessionStorage.getItem("token") ?? undefined,
        refreshToken: sessionStorage.getItem("refreshToken") ?? undefined,
        idToken: sessionStorage.getItem("idToken") ?? undefined
      }).then(() => {
        setInitialized(true);
        processAuthenticated();
      }).catch((error) => {
        console.error(error);
        if (error === true) {
          login();
        } else {
          nextKeycloakInitError(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("token") ?? undefined;
    if (keycloak.authenticated && keycloak.token !== token && window.location.pathname !== LINK_LOGOUT) {
      setCurrentUserToken(keycloak.token, keycloak.refreshToken, keycloak.idToken, keycloak.tokenParsed);
      setRefreshTokenTimeout(Math.min(keycloak.refreshTokenParsed.exp, keycloak.tokenParsed.exp));
      processAuthenticated();
    }
  }, [keycloak.authenticated]);

  const contextValue = useMemo(
    () => ({
      keycloak,
      initialized
    }),
    [keycloak, initialized]
  );

  if (!initialized) {
    return (
      <KeycloakLoading />
    );
  }

  return <KeycloakContext.Provider value={contextValue} {...props} />;
};

export const useKeycloak = () => {
  return useContext(KeycloakContext);
};

const processAuthenticated = () => {
  const token = sessionStorage.getItem("token") ?? undefined;
  const { data, loading } = userMeGlobalState.getValue();

  if (token) {
    if (!data && !loading) {
      getUserMe().catch((error) => {
        console.error(error);
      });
    }
  }
};

export const login = () => {
  const [language] = i18n.language.split("-");
  document.cookie = "KEYCLOAK_LOCALE=" + language + "; path=/;";
  keycloak.login({ locale: language }).catch(() => {
    setCurrentUserToken(null, null, null);
    window.location.reload();
  });
};

export const logout = () => {
  const token = sessionStorage.getItem("token") ?? undefined;
  setCurrentUserToken(null, null, null);
  if (token) {
    keycloak.logout().catch(console.error);
  }
};

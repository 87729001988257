import { GlobalState } from "@nbp/dnafe-material-ui/dist/hooks/GlobalState";
import { getGlobalStateHookSetterGetter } from "@nbp/dnafe-material-ui/dist/hooks/globalStateHooks";
import { useEffect, useRef } from "react";
import { useCurrentUser } from "./auth";
import { useCookies } from "@nbp/dnafe-material-ui/dist/hooks/cookies";

export interface MatomoState {
  initializing: boolean;
  initialized: boolean;
  tracker?: {
    [key: string]: any,
    trackEvent: (category: string, action: string, name?: string, value?: string) => void,
    trackPageView: (pageName: string) => void,
    setCustomUrl: (url: string) => void,
    setUserId: (userId: string) => void
  };
}

const matomoState = new GlobalState<MatomoState>({ initializing: false, initialized: false, tracker: null });
export const [useMatomoState, setMatomoState, getMatomoState] = getGlobalStateHookSetterGetter(matomoState);

export const useMatomo = () => {
  const currentUser = useCurrentUser();
  const state = useMatomoState();
  const ref = useRef(false);
  const { MATOMO_CLOUD_HOST, MATOMO_SITE_ID, MATOMO_ENABLED } = window.env;
  const { cookieConsent } = useCookies();

  useEffect(() => {
    if (state.initialized && currentUser?.decodedToken) {
      state.tracker.setUserId(currentUser?.decodedToken?.sub);
    }
  }, [currentUser?.token, state.initialized]);

  useEffect(() => {
    const state = getMatomoState();
    if (MATOMO_ENABLED === "true" && cookieConsent === "true") {
      if (!ref.current && !state.initializing && !state.initialized) {
        ref.current = true;
        setMatomoState({ initializing: true, initialized: false, tracker: null });
        (function() {
          const d = document;
          const g = d.createElement("script");
          const s = d.getElementsByTagName("script")[0];
          g.async = true;
          g.src = `//cdn.matomo.cloud/${MATOMO_CLOUD_HOST}/matomo.js`;
          s.parentNode.insertBefore(g, s);
          g.onload = () => {
            const tracker = Matomo.getTracker(`https://${MATOMO_CLOUD_HOST}/matomo.php`, MATOMO_SITE_ID);
            setMatomoState({ initializing: false, initialized: true, tracker });
          };
        })();
      }
    }
  }, []);

  return state;
};

import * as React from "react";
import { useEffect, useMemo } from "react";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useTranslation } from "react-i18next";
import { hasPermission, useCurrentUser } from "../../hooks/auth";
import { PERMISSION } from "../../constants/permission";
import {
  LINK_INSTITUTIONS,
  LINK_REGISTRATION_AUTHORITIES,
  LINK_REGISTRATION_AUTHORITIES_ADMINISTRATION
} from "../../constants/navigate";
import PageHeader from "../../components/Layout/PageHeader/PageHeader";
import { Cards, PageContent } from "@nbp/dnafe-material-ui/dist/components";
import { Button, Card, CardContent } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useUserMeData } from "../../hooks/user";
import "./LandingPage.scss";
import { getMyAdminInstitution } from "../../hooks/institution";
import { IssuersSVG, OrganizationsSVG, RegistrationAuthoritiesSVG } from "../../svg";

const LandingPage = () => {
  usePageTitle("");
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const userMe = useUserMeData();
  const { registrationAuthorityAdmins, institutionAdmins, institutionIssuers } = userMe ?? {};
  const issuerInstitutions = institutionIssuers?.filter(({ institution: { id } }: any) => {
    return !getMyAdminInstitution(id);
  });

  const isSuperUser = hasPermission(PERMISSION.REGISTRATION_AUTHORITY_LIST);
  const isRegistrationAuthority = !!registrationAuthorityAdmins?.length;
  const isInstitutionAdmin = !!institutionAdmins?.length;
  const isInstitutionIssuer = !!institutionIssuers?.length;
  const isOnlyOneInstitution = (institutionAdmins?.length + institutionIssuers?.length) === 1;
  const isOnlyOneRegistrationAuthority = registrationAuthorityAdmins?.length === 1;

  const isNoRoles = !isSuperUser && !isRegistrationAuthority && !isInstitutionAdmin && !isInstitutionIssuer;
  const isOnlyOneMenu = [isSuperUser, isRegistrationAuthority, (isInstitutionAdmin || isInstitutionIssuer)].filter(x => !!x).length === 1;

  const handleRegistrationAuthorityNavigate = () => {
    if (isOnlyOneRegistrationAuthority) {
      navigate(LINK_REGISTRATION_AUTHORITIES + "/" + registrationAuthorityAdmins[0].registrationAuthority.id);
    } else {
      navigate(LINK_REGISTRATION_AUTHORITIES);
    }
  };

  const handleInstitutionNavigate = () => {
    if (isOnlyOneInstitution) {
      const institution = (isInstitutionAdmin ? institutionAdmins : institutionIssuers)[0].institution;
      navigate(LINK_INSTITUTIONS + "/" + institution.id);
    } else {
      navigate(LINK_INSTITUTIONS);
    }
  };

  useEffect(() => {
    if (userMe && isOnlyOneMenu) {
      if (isSuperUser) {
        navigate(LINK_REGISTRATION_AUTHORITIES_ADMINISTRATION);
      } else if (isRegistrationAuthority) {
        handleRegistrationAuthorityNavigate();
      } else if (isInstitutionAdmin || isInstitutionIssuer) {
        handleInstitutionNavigate();
      }
    }
  }, [isOnlyOneMenu, userMe]);

  const cards = useMemo(() => {
    const cards = [
      hasPermission(PERMISSION.REGISTRATION_AUTHORITY_LIST) && {
        header: t("startPage.registrationAuthorities"),
        label: t("startPage.registrationAuthoritiesDescription"),
        icon: <RegistrationAuthoritiesSVG />,
        component: <NavLink to={LINK_REGISTRATION_AUTHORITIES_ADMINISTRATION}>
          <Button variant="contained" size="small" color="primary">
            {t("menu.registrationAuthoritiesAdministration")}
          </Button>
        </NavLink>
      },
      registrationAuthorityAdmins?.length && {
        header: t("startPage.institutions"),
        label: t("startPage.institutionsDescription"),
        icon: <OrganizationsSVG />,
        external: false,
        component: <ul>{registrationAuthorityAdmins.map(({ registrationAuthority: { id, name } }) =>
          (<li key={id}><NavLink to={LINK_REGISTRATION_AUTHORITIES + "/" + id}>{name}</NavLink></li>)
        )}</ul>
      },
      institutionAdmins?.length && {
        header: t("startPage.institutionIssuers"),
        label: t("startPage.institutionsIssuersDescription"),
        icon: <IssuersSVG />,
        external: false,
        component: <ul>{institutionAdmins.map(({ id, institution }) =>
          (<li key={id}>
            <NavLink to={LINK_INSTITUTIONS + "/" + institution?.id}>{institution?.name}</NavLink>
          </li>)
        )}</ul>
      },
      issuerInstitutions?.length && {
        header: t("startPage.availableInstitutions"),
        label: t("startPage.availableInstitutionsDescription"),
        external: false,
        component: <ul>{issuerInstitutions.map(({ id, institution }) =>
          (<li key={id}>
            <NavLink to={LINK_INSTITUTIONS + "/" + institution?.id}>{institution?.name}</NavLink>
          </li>)
        )}</ul>
      }
    ];
    return cards.filter(item => !!item);
  }, [language, currentUser, userMe]);

  if (isNoRoles || isOnlyOneMenu) {
    return null;
  }

  return (
    <div className="LandingPage">
      <PageHeader title={t("startPage.title")} />
      <PageContent>
        <p className="margin-bottom-l">{t("startPage.description")}</p>
        <Cards amountInRow={3}>
          {cards.map(({ label, header, external, component, icon }, index) => (
            <Card key={[index, label].join("_")} className="flex-auto" variant="outlined">
              <CardContent>
                <h3 className="text-title-2">{header}</h3>
                {!!icon && icon}
                <p>{label}</p>
                {component}
              </CardContent>
            </Card>
          ))}
        </Cards>
      </PageContent>
    </div>
  );
};

export default React.memo(LandingPage);

import { Button } from "@mui/material";
import React, { FC, memo, useId } from "react";
import { useTranslation } from "react-i18next";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import { BaseDialog } from "@nbp/dnafe-material-ui/dist/components";
import { SimpleDialogProps } from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import { InstitutionBaseResponse, InstitutionRequest } from "../../api";
import { InstitutionRequestData } from "../../models/institution";
import InstitutionDetailsForm from "./InstitutionDetailsForm";
import { useForm } from "react-hook-form";
import { getInstitutionDefaultValues } from "../../helpers/institution";
import { splitInstitution, useSplitInstitutionLoading } from "../../hooks/institution";
import moment from "moment/moment";
import { FORMAT_DATE_SERVER } from "../../constants/date";
import { showError, showSuccess } from "@nbp/dnafe-material-ui/dist/hooks/snackbar";

interface InstitutionsSplitToNewDialogProps extends SimpleDialogProps {
  data: InstitutionBaseResponse;
  membersActions: { [key: string]: string };
  onSplit: () => void;
  registrationAuthorityId: string;
}

const InstitutionsSplitToNewDialog: FC<InstitutionsSplitToNewDialogProps> = (
  {
    onClose,
    open,
    data,
    membersActions,
    onSplit,
    registrationAuthorityId
  }
) => {
  const { t } = useTranslation();
  const splitInstitutionLoading = useSplitInstitutionLoading();
  const formId = useId();

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: getInstitutionDefaultValues()
  });

  const successHandler = (data: InstitutionBaseResponse) => {
    showSuccess(t("institution.split.splitToNewSuccess"));
    onSplit();
    onClose();
  };

  const errorHandler = (error: Error) => showError(error.message);

  const onSubmit = useEvent(async (values: InstitutionRequest) => {
    const institutionToCreate = new InstitutionRequestData({
      ...values,
      registrationAuthorityId,
      openingDate: moment().format(FORMAT_DATE_SERVER)
    });
    await splitInstitution({
      args: { institutionId: data?.id }, body: {
        institutionToCreate,
        memberActions: Object.keys(membersActions).map((key: string) => ({
          memberId: key,
          action: membersActions[key]
        }))
      }
    }).then(successHandler).catch(errorHandler);
  });

  const loading = splitInstitutionLoading;

  return (
    <BaseDialog
      className="InstitutionsSplitToNewDialog" open={open} onClose={onClose} loading={loading} size="medium"
      title={t("institution.split.newInstitutionTitle")} closeOnBackdropClick={!loading} closeOnEscapeKeyDown={!loading}
      actions={(<>
        <Button variant="outlined" size="large" onClick={onClose} disabled={loading} autoFocus>
          {t("main.cancel")}
        </Button>
        <Button variant="contained" size="large" form={formId} type="submit" name="import" disabled={loading || !open}>
          {t("institution.split.splitToNew")}
        </Button>
      </>)}>
      <InstitutionDetailsForm
        formId={formId} onSubmit={onSubmit} data={data} control={control} handleSubmit={handleSubmit}
        getValues={getValues}
      />
    </BaseDialog>
  );
};

export default memo(InstitutionsSplitToNewDialog);

import * as React from "react";
import { useTranslation } from "react-i18next";
import "./KeycloakLoading.scss";
import { NavLink } from "react-router-dom";
import PageContent from "@nbp/dnafe-material-ui/dist/components/PageContent/PageContent";
import { LoadingOverlay } from "@nbp/dnafe-material-ui/dist/components";
import { LINK_ROOT } from "../../constants/navigate";
import { useKeycloakInitError } from "../../hooks/auth";

const KeycloakLoading = () => {
  const { t } = useTranslation();
  const keycloakInitError = useKeycloakInitError();

  if (!keycloakInitError) {
    return (
      <LoadingOverlay className="KeycloakLoading" loading>
        &nbsp;
      </LoadingOverlay>
    );
  }

  return (
    <div className="KeycloakLoading">
      <PageContent>
        {<p>
          {t("error.keycloakInit")}
        </p>}
        <p>
          <NavLink to={LINK_ROOT} onClick={window.location.reload}>{t("main.retry")}</NavLink>
        </p>
      </PageContent>
    </div>
  );
};

export default React.memo(KeycloakLoading);

import axios from "axios";
import React, { createContext, memo, ReactNode, useEffect } from "react";
import { logout } from "../keycloak";

interface AxiosErrorInterceptorProps {
  children: ReactNode;
}

const InterceptorContext = createContext({});

const handleUMA = async (authzServerLocation: string, ticket: string, response: any) => {
  const umaResponse: any = await axios.post(
    authzServerLocation,
    { grant_type: "urn:ietf:params:oauth:grant-type:uma-ticket", ticket },
    { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
  );
  const accessToken = umaResponse?.data?.access_token;
  if (accessToken) {
    const config = response?.config ?? {};
    const { url, method, data } = config;
    const headers = { ...config?.headers, "Authorization": "Bearer " + accessToken };
    switch (method) {
      case "post":
        return await axios.post(url, data, { headers });
      case "put":
        return await axios.put(url, data, { headers });
      case "delete":
        return await axios.delete(url, { headers });
      case "get":
        return await axios.get(url, { headers });
    }
  }
  return null;
};

const AxiosErrorInterceptorProvider = ({ children }: AxiosErrorInterceptorProps) => {

  useEffect(() => {
    const handler = axios.interceptors.response.use(
      res => res,
      async (err) => {
        const status = err.response?.status;
        console.debug("AxiosErrorInterceptorProvider", err);
        if (status === 401) {
          const data = err.response?.data ?? {};
          const { authzServerLocation, ticket } = data;
          if (authzServerLocation && ticket) {
            return await handleUMA(authzServerLocation, ticket, err.response);
          } else {
            logout();
          }
        }
        throw err;
      });
    return () => axios.interceptors.response.eject(handler);
  }, []);

  return (
    <InterceptorContext.Provider value={null}>
      {children}
    </InterceptorContext.Provider>
  );
};

export default memo(AxiosErrorInterceptorProvider);

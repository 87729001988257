import React, { memo } from "react";
import { InstitutionBaseResponse } from "../../api";
import moment from "moment/moment";
import { INSTITUTION_STATUS_CLOSED, INSTITUTION_STATUS_OPEN } from "../../constants/institution";
import { FORMAT_DATE_CLIENT } from "../../constants/date";
import { useTranslation } from "react-i18next";
import { StatusChip } from "@nbp/dnafe-material-ui/dist/components";

const colors = {
  [INSTITUTION_STATUS_OPEN]: "primary",
  [INSTITUTION_STATUS_CLOSED]: "default"
};

const InstitutionOperatingStatus = ({ operatingStatus, openingDate, closingDate }: InstitutionBaseResponse) => {
  const { t } = useTranslation();

  if (!operatingStatus) {
    return null;
  }

  const statusKey = "institutions.status." + operatingStatus;
  const statusTranslated = t(statusKey);
  const statusValue = statusKey === statusTranslated ? operatingStatus : statusTranslated;

  const openingMoment = moment(openingDate);
  const closingMoment = moment(closingDate);
  const showOpens = operatingStatus === INSTITUTION_STATUS_CLOSED && openingMoment.isAfter(moment());
  const showCloses = operatingStatus === INSTITUTION_STATUS_OPEN && closingMoment.isAfter(moment());

  return (
    <div className="InstitutionOperatingStatus">
      <StatusChip status={operatingStatus} label={statusValue} colors={colors} />
      {showOpens && <div className="margin-top-xxs">
        {t("institutions.opens", { date: openingMoment.format(FORMAT_DATE_CLIENT) })}
      </div>}
      {showCloses && <div className="margin-top-xxs">
        {t("institutions.closes", { date: closingMoment.format(FORMAT_DATE_CLIENT) })}
      </div>}
    </div>
  );
};

export default memo(InstitutionOperatingStatus);

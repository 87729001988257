import { Button } from "@mui/material";
import React, { FC, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseDialog, DialogScrollBody } from "@nbp/dnafe-material-ui/dist/components";
import { SimpleDialogProps } from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import { InstitutionDetailedResponse } from "../../api";
import "./InstitutionMergeDialog.scss";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import InstitutionSplitMembersTable from "./InstitutionSplitMembersTable";
import { SPLIT_MEMBER_KEEP } from "../../constants/institution";
import { useModal } from "mui-modal-provider";
import InstitutionsSplitToNewDialog from "./InstitutionsSplitToNewDialog";

interface MembersActions {
  [key: string]: string;
}

interface InstitutionSplitDialogProps extends SimpleDialogProps {
  data: InstitutionDetailedResponse;
  onSplit: () => void;
}

const InstitutionSplitDialog: FC<InstitutionSplitDialogProps> = (
  {
    onClose, open, data, onSplit
  }
) => {
  const { t } = useTranslation();
  const { showModal } = useModal();

  const initialActions: MembersActions = {};
  data?.admins?.forEach(({ userId }) => {
    initialActions[userId] = SPLIT_MEMBER_KEEP;
  });
  data?.issuers?.forEach(({ userId }) => {
    initialActions[userId] = SPLIT_MEMBER_KEEP;
  });

  const [memberActions, setMemberActions] = useState<MembersActions>(initialActions);

  const setMemberAction = useEvent((id: string, action: string) => {
    setMemberActions({ ...memberActions, [id]: action });
  });

  const onSplitEvent = useEvent(() => {
    onClose();
    onSplit();
  });

  const showSplitToNewDialog = useEvent(() => showModal((props: SimpleDialogProps) => (
    <InstitutionsSplitToNewDialog
      {...props} membersActions={memberActions} data={data} onSplit={onSplitEvent}
      registrationAuthorityId={data?.registrationAuthority?.id} />
  )));

  return (
    <BaseDialog
      className="InstitutionSplitDialog" open={open} onClose={onClose} size="large"
      title={t("institution.split.dialogTitle")} closeOnBackdropClick={false} closeOnEscapeKeyDown={false}
      actions={(<>
        <Button variant="outlined" size="large" onClick={onClose}>
          {t("main.close")}
        </Button>
        <Button variant="contained" size="large" onClick={showSplitToNewDialog}>
          {t("institution.split.continue")}
        </Button>
      </>)}
    >
      <DialogScrollBody>
        <div className="margin-bottom-s">
          <h3 className="text-large margin-bottom-0">{t("institution.member.adminsTitle")}</h3>
        </div>
        <InstitutionSplitMembersTable data={data?.admins ?? []} setAction={setMemberAction} />
        <div className="margin-bottom-s margin-top-l">
          <h3 className="text-large margin-bottom-0">{t("institution.member.issuersTitle")}</h3>
        </div>
        <InstitutionSplitMembersTable data={data?.issuers ?? []} setAction={setMemberAction} />
      </DialogScrollBody>
    </BaseDialog>
  );
};

export default memo(InstitutionSplitDialog);

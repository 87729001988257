import * as React from "react";
import {useTranslation} from "react-i18next";
import "./ErrorPage.scss";
import {Navigate, NavLink, useRouteError} from "react-router-dom";
import {usePageTitle} from "../../hooks/usePageTitle";
import {LINK_LOGOUT} from "../../constants/navigate";

const ErrorPage = () => {
  usePageTitle("menu.error");
  const {t} = useTranslation();
  const routeError: any = useRouteError();

  console.error(routeError);

  return (
    <div className="ErrorPage">
      {routeError?.response?.status === 401 && <Navigate to={LINK_LOGOUT}/>}
      {!!routeError && <p>
        {t("error.routeError")} {routeError?.status || routeError?.code}
      </p>}
      <p>
        <NavLink to="/">{t("main.backToRoot")}</NavLink>
      </p>
      <p>
        <NavLink to="/logout">{t("main.logout")}</NavLink>
      </p>
    </div>
  );
};

export default React.memo(ErrorPage);

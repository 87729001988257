import { Button } from "@mui/material";
import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { BaseDialog, ValueWithLabel } from "@nbp/dnafe-material-ui/dist/components";
import { SimpleDialogProps } from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import { AddressRequest } from "../../api";

interface InstitutionAddressViewDialogProps extends SimpleDialogProps {
  data?: AddressRequest;
}

const InstitutionAddressViewDialog: FC<InstitutionAddressViewDialogProps> = (
  { onClose, open, data }
) => {
  const { t } = useTranslation();

  return (
    <BaseDialog
      className="InstitutionAddressViewDialog" open={open} onClose={onClose}
      title={t("institution.addresses.addressTitle")} size="medium" closeOnBackdropClick closeOnEscapeKeyDown
      actions={(<Button variant="outlined" size="large" onClick={onClose}>
        {t("main.close")}
      </Button>)}
    >
      <ValueWithLabel label={t("institution.addresses.dependencyName")} value={data.dependencyName} />
      <div className="flex-row form-row flex-gap">
        <div className="flex-1">
          <ValueWithLabel label={t("institution.city")} value={data.city} />
        </div>
        <div className="flex-1">
          <ValueWithLabel label={t("institution.postalCode")} value={data.postalCode} />
        </div>
      </div>
      <ValueWithLabel label={t("institution.street")} value={data.street} />
      <div className="flex-row form-row flex-gap">
        <div className="flex-1">
          <ValueWithLabel label={t("institution.addresses.phone")} value={data.phone} />
        </div>
        <div className="flex-1">
          <ValueWithLabel label={t("institution.addresses.fax")} value={data.fax} />
        </div>
      </div>
      <div className="flex-row form-row flex-gap">
        <div className="flex-1">
          <ValueWithLabel label={t("institution.addresses.email")} value={data.email} />
        </div>
        <div className="flex-1">
          <ValueWithLabel label={t("institution.addresses.website")} value={data.website} />
        </div>
      </div>
    </BaseDialog>
  );
};

export default memo(InstitutionAddressViewDialog);

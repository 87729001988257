import { Button, CircularProgress } from "@mui/material";
import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import { BaseDialog } from "@nbp/dnafe-material-ui/dist/components";
import { SimpleDialogProps } from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import { abortImportLoop, useImportInstitutionsProgress } from "../../hooks/institution";
import ProgressBar from "../ProgressBar";
import moment from "moment";
import i18n from "i18next";

const InstitutionImportProgressDialog: FC<SimpleDialogProps> = (
  {
    onClose, open
  }
) => {
  const { t } = useTranslation();

  const importInstitutionsProgress = useImportInstitutionsProgress();

  const handleAbort = useEvent(() => {
    abortImportLoop();
    onClose();
  });

  if (!importInstitutionsProgress) {
    return null;
  }

  const { data, index, results } = importInstitutionsProgress;
  const total = data?.length;
  const processed = results?.length;
  const imported = results?.filter(({ success }) => success).length;
  const failed = processed - imported;

  const isInProgress = index < data.length;
  const progress = index / data.length * 100;
  const averageTime = results.length ? results.map(item => item.time).reduce((a, b) => a + b, 0) / results.length : 0;

  return (
    <BaseDialog
      className="InstitutionImportProgressDialog" open={open} onClose={handleAbort} size="medium"
      title={t("institutions.importProgressTitle")} closeOnBackdropClick={false} closeOnEscapeKeyDown={false}
      actions={(
        isInProgress ?
          <Button variant="outlined" size="large" onClick={handleAbort}>
            {t("main.abort")}
          </Button>
          :
          <Button variant="contained" size="large" onClick={onClose}>
            {t("main.close")}
          </Button>
      )}
    >
      <ProgressBar value={progress} />
      <div className="flex-row">
        <div className="flex-auto">
          {t("institutions.importProgress", { total, processed })}
        </div>
        {!!averageTime && progress !== 100 && <div className="text-right">
          {t("main.timeLeft")}: {moment.duration((total - processed) * averageTime, "milliseconds").locale(i18n.language.split("-")[0]).humanize()}
        </div>}
      </div>
      <div className="text-center" style={{opacity: progress === 100 ? 0 : 1}}><CircularProgress /></div>
      <div>{t("institutions.importSucceed")}: {imported}</div>
      <div>{t("institutions.importFailed")}: {failed}</div>
    </BaseDialog>
  );
};

export default memo(InstitutionImportProgressDialog);

import { TFunction } from "i18next";
import { AddressRequest, InstitutionBaseResponse } from "../api";
import { STATES } from "../constants/state";
import { ADDRESS_ROLE_MAIN, INSTITUTION_COUNTRY_CODE } from "../constants/institution";
import { FORMAT_DATE_SERVER } from "../constants/date";
import moment from "moment";

export const getInstitutionAddress = (
  { state, addresses }: InstitutionBaseResponse, t: TFunction
): string => {
  const stateKey = "state." + state;
  const stateTranslated = t(stateKey);
  const stateValue = stateKey === stateTranslated ? state : stateTranslated;
  const address = (addresses ?? [])[0];
  const { city, postalCode, street } = address ?? {};
  return [stateValue, city, postalCode, street].filter(item => !!item).join(", ");
};

export const getDependencyAddress = ({ city, postalCode, street }: AddressRequest, t: TFunction): string =>
  [city, postalCode, street].filter(item => !!item).join(", ");

export const getInstitutionDefaultValues = () => ({
  name: "",
  countryCode: INSTITUTION_COUNTRY_CODE,
  state: "",
  city: "",
  postalCode: "",
  street: "",
  type: "",
  openingDate: moment().format(FORMAT_DATE_SERVER),
  closingDate: "",
  phone: "",
  fax: "",
  email: "",
  website: ""
});

export const getInstitutionDataValues = (data: any) => {
  const values = getInstitutionDefaultValues() as any;
  Object.keys(values).forEach(key => {
    values[key] = data[key] ?? values[key];
  });
  values.state = STATES.includes(data?.state) ? data?.state : "";
  const address = data.addresses?.find((item: AddressRequest) => item.roleOfAddress === ADDRESS_ROLE_MAIN);
  if (address) {
    values.city = address.city ?? "";
    values.postalCode = address.postalCode ?? "";
    values.street = address.street ?? "";
    values.phone = address.phone ?? "";
    values.fax = address.fax ?? "";
    values.email = address.email ?? "";
    values.website = address.website ?? "";
    values.addressId = address.id;
  }
  return values;
};

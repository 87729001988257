import { Button } from "@mui/material";
import React, { memo, useId } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import { BaseDialog, FormField } from "@nbp/dnafe-material-ui/dist/components";
import { SimpleDialogProps } from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import { RegistrationAuthorityRequest } from "../../api";
import { createRegistrationAuthority, useCreateRegistrationAuthorityLoading } from "../../hooks/registrationAuthority";
import { AxiosError } from "axios";
import { useAxiosErrorHandler } from "../../hooks/general";
import { showError, showSuccess } from "@nbp/dnafe-material-ui/dist/hooks/snackbar";

interface RegistrationAuthorityDetailsDialogProps extends SimpleDialogProps {
  onUpdate?: () => void;
}

const RegistrationAuthorityCreateDialog = ({ onClose, open, onUpdate }: RegistrationAuthorityDetailsDialogProps) => {
  const { t } = useTranslation();
  const createRegistrationAuthorityLoading = useCreateRegistrationAuthorityLoading();
  const formId = useId();
  const axiosErrorHandler = useAxiosErrorHandler();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: ""
    }
  });

  const successHandler = () => {
    showSuccess(t("registrationAuthority.saveSuccess"));
    onClose();
    onUpdate();
  };

  const errorHandler = (error: AxiosError) => {
    if (error.response.status === 409) {
      showError(t("registrationAuthority.nameUniqueError"));
    } else {
      axiosErrorHandler(error);
    }
  };

  const onSubmit = useEvent(async (values: RegistrationAuthorityRequest) => {
    await createRegistrationAuthority(values).then(successHandler).catch(errorHandler);
  });

  const loading = createRegistrationAuthorityLoading;

  return (
    <BaseDialog
      className="RegistrationAuthorityCreateDialog" open={open} onClose={onClose}
      title={t("registrationAuthority.createTitle")}
      loading={loading} closeOnBackdropClick={!loading} closeOnEscapeKeyDown={!loading} size="medium"
      actions={(<>
        <Button variant="outlined" size="large" onClick={onClose} disabled={loading}>
          {t("main.cancel")}
        </Button>
        <Button variant="contained" size="large" form={formId} type="submit" disabled={loading || !open}>
          {t("main.save")}
        </Button>
      </>)}>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <FormField
          control={control} name="name" required label={t("registrationAuthority.name")} autoFocus fullWidth
        />
      </form>
    </BaseDialog>
  );
};

export default memo(RegistrationAuthorityCreateDialog);

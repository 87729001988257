import React, { memo, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import PopoverMenu, { PopoverMenuItemProps } from "@nbp/dnafe-material-ui/dist/components/PopoverMenu";
import { setInstitutionActionsPopover, useInstitutionActionsPopover } from "../../hooks/institution";
import {
  INSTITUTION_STATUS_CLOSED,
  INSTITUTION_STATUS_CLOSED_FOREVER,
  INSTITUTION_STATUS_OPEN
} from "../../constants/institution";

interface InstitutionActionsMenuPopoverProps {
  onClose: () => void;
  onOpen: () => void;
  onEdit: () => void;
  onMerge: () => void;
  onSplit: () => void;
  onPermanentlyClose: () => void;
  operatingStatus: string;
}

export const InstitutionActionsMenuPopover = (
  { onClose, onOpen, onEdit, onMerge, onSplit, onPermanentlyClose, operatingStatus }: InstitutionActionsMenuPopoverProps
) => {
  const { t, i18n: { language } } = useTranslation();
  const popover = useInstitutionActionsPopover();
  const anchor = popover?.anchor;

  const closeMenu = useEvent(() => setInstitutionActionsPopover({ anchor: null }));
  useEffect(closeMenu, []);

  const menuItems = useMemo<PopoverMenuItemProps[]>(() => [
    {
      text: t("institution.editInstitution"),
      onClick: onEdit,
      disabled: operatingStatus === INSTITUTION_STATUS_CLOSED_FOREVER
    },
    operatingStatus === INSTITUTION_STATUS_OPEN && {
      text: t("institutions.closeInstitution"),
      onClick: onClose
    },
    operatingStatus === INSTITUTION_STATUS_CLOSED && {
      text: t("institutions.openInstitution"),
      onClick: onOpen
    },
    operatingStatus !== INSTITUTION_STATUS_CLOSED_FOREVER && {
      text: t("institutions.permanentlyClose"),
      onClick: onPermanentlyClose
    },
    {
      text: t("institution.merge.mergeInstitution"),
      onClick: onMerge,
      disabled: operatingStatus !== INSTITUTION_STATUS_OPEN
    },
    {
      text: t("institution.split.splitInstitution"),
      onClick: onSplit,
      disabled: operatingStatus !== INSTITUTION_STATUS_OPEN
    }
  ].filter(item => !!item), [language, operatingStatus]);

  return (
    <PopoverMenu
      className="InstitutionActionsMenuPopover"
      anchor={anchor}
      onClose={closeMenu}
      menuItems={menuItems}
    />
  );
};

export default memo(InstitutionActionsMenuPopover);

import { Autocomplete, Button, TextField } from "@mui/material";
import React, { FC, memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import { BaseDialog, DialogScrollBody } from "@nbp/dnafe-material-ui/dist/components";
import { SimpleDialogProps } from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import { AutocompleteOption } from "../../models/form";
import { STATES } from "../../constants/state";

interface InstitutionImportMappingDialogProps extends SimpleDialogProps {
  fields?: string[];
  onChange?: (fieldsMap: { [key: string]: AutocompleteOption[] | string }) => void;
}

const InstitutionImportMappingDialog: FC<InstitutionImportMappingDialogProps> = (
  {
    onClose, open, fields, onChange
  }
) => {
  const { t, i18n: { language } } = useTranslation();

  let stored: any = {};
  const storedString = localStorage.getItem(fields.join(","));
  if (storedString) {
    try {
      stored = JSON.parse(storedString);
    } catch (error) {
      console.error(error);
    }
  }

  const [loading, setLoading] = useState(false);
  const [nameValues, setNameValues] = useState<AutocompleteOption[]>(stored.name ?? []);
  const [cityValues, setCityValues] = useState<AutocompleteOption[]>(stored.city ?? []);
  const [postalCodeValues, setPostalCodeValues] = useState<AutocompleteOption[]>(stored.postalCode ?? []);
  const [streetValues, setStreetValues] = useState<AutocompleteOption[]>(stored.street ?? []);
  const [typeValues, setTypeValues] = useState<AutocompleteOption[]>(stored.type ?? []);
  const [stateValue, setStateValue] = useState<string>((stored.state || [])[0]?.label ?? "");

  const [phoneValues, setPhoneValues] = useState<AutocompleteOption[]>(stored.phone ?? []);
  const [faxValues, setFaxValues] = useState<AutocompleteOption[]>(stored.fax ?? []);
  const [emailValues, setEmailValues] = useState<AutocompleteOption[]>(stored.email ?? []);
  const [websiteValues, setWebsiteValues] = useState<AutocompleteOption[]>(stored.website ?? []);

  const fieldOptions: any = useMemo(() => fields?.map((field, index) => ({ id: index, label: field })), [fields]);

  const onSubmit = useEvent(async () => {
    const fieldsMap = {
      name: nameValues,
      type: typeValues,
      state: [{ label: stateValue }] as AutocompleteOption[],
      city: cityValues,
      postalCode: postalCodeValues,
      street: streetValues,
      email: emailValues,
      phone: phoneValues,
      fax: faxValues,
      website: websiteValues
    };
    localStorage.setItem(fields.join(","), JSON.stringify(fieldsMap));
    setLoading(true);
    setTimeout(() => {
      onChange(fieldsMap);
      onClose();
    }, 0);
  });

  const compareOptionToValue = useEvent((option: AutocompleteOption, value: AutocompleteOption) => {
    return option?.id === value?.id;
  });

  const stateOptions: AutocompleteOption[] = useMemo(
    () => STATES.map((state) => ({ id: state, label: t("state." + state) })),
    []
  );

  const getOptionLabel = useCallback((option: { id: string, label: string }) => {
    return option?.id === undefined ? `"${option?.label}"` : option?.label;
  }, [language]);

  const createOnBlurEvent = useCallback((onBlur: (values: AutocompleteOption[]) => void, values: AutocompleteOption[]) => {
    return (event: any) => {
      const value = (event.target as HTMLInputElement).value;
      if (value) {
        onBlur([...values, { id: undefined, label: value }]);
      }
    };
  }, []);

  return (
    <BaseDialog
      className="InstitutionImportMappingDialog" open={open} onClose={onClose} loading={loading} size="medium"
      title={t("institutions.importMappingTitle")} closeOnBackdropClick={false} closeOnEscapeKeyDown={false}
      actions={(<>
        <Button variant="outlined" size="large" onClick={onClose}>
          {t("main.cancel")}
        </Button>
        <Button variant="contained" size="large" autoFocus onClick={onSubmit} disabled={!stateValue}>
          {t("main.process")}
        </Button>
      </>)}
    >
      <DialogScrollBody>
        <form autoComplete="off" className="flex-row form-row flex-gap">
          <Autocomplete
            options={fieldOptions}
            onChange={(event, values: AutocompleteOption[]) => {
              setNameValues(values);
            }}
            value={nameValues}
            fullWidth
            multiple
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={compareOptionToValue}
            renderInput={(params) => (
              <TextField
                {...params} label={t("institutions.name")} onBlur={createOnBlurEvent(setNameValues, nameValues)} />
            )}
          />
        </form>
        <form autoComplete="off" className="flex-row form-row flex-gap">
          <Autocomplete
            options={stateOptions}
            onChange={(event, value: AutocompleteOption) => setStateValue(value?.id)}
            value={stateOptions.find(item => item.id === stateValue) ?? null}
            disableClearable
            fullWidth
            isOptionEqualToValue={compareOptionToValue}
            renderInput={(params) => (
              <TextField {...params} label={t("institutions.state")} />
            )}
          />
        </form>
        <form autoComplete="off" className="flex-row form-row flex-gap">
          <Autocomplete
            options={fieldOptions}
            onChange={(event, values: AutocompleteOption[]) => setCityValues(values)}
            value={cityValues}
            fullWidth
            multiple
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={compareOptionToValue}
            renderInput={(params) => (
              <TextField
                {...params} label={t("institutions.city")} onBlur={createOnBlurEvent(setCityValues, cityValues)} />
            )}
          />
          <Autocomplete
            options={fieldOptions}
            onChange={(event, values: AutocompleteOption[]) => setPostalCodeValues(values)}
            value={postalCodeValues}
            fullWidth
            multiple
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={compareOptionToValue}
            renderInput={(params) => (
              <TextField
                {...params} label={t("institutions.postalCode")}
                onBlur={createOnBlurEvent(setPostalCodeValues, postalCodeValues)}
              />
            )}
          />
        </form>
        <form autoComplete="off" className="flex-row form-row flex-gap">
          <Autocomplete
            options={fieldOptions}
            onChange={(event, values: AutocompleteOption[]) => setStreetValues(values)}
            value={streetValues}
            fullWidth
            multiple
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={compareOptionToValue}
            renderInput={(params) => (
              <TextField
                {...params} label={t("institutions.street")}
                onBlur={createOnBlurEvent(setStreetValues, streetValues)} />
            )}
          />
        </form>
        <form autoComplete="off" className="flex-row form-row flex-gap">
          <Autocomplete
            options={fieldOptions}
            onChange={(event, values: AutocompleteOption[]) => setPhoneValues(values)}
            value={phoneValues}
            fullWidth
            multiple
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={compareOptionToValue}
            renderInput={(params) => (
              <TextField
                {...params} label={t("institution.addresses.phone")}
                onBlur={createOnBlurEvent(setPhoneValues, phoneValues)} />
            )}
          />
          <Autocomplete
            options={fieldOptions}
            onChange={(event, values: AutocompleteOption[]) => setFaxValues(values)}
            value={faxValues}
            fullWidth
            multiple
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={compareOptionToValue}
            renderInput={(params) => (
              <TextField
                {...params} label={t("institution.addresses.fax")}
                onBlur={createOnBlurEvent(setFaxValues, faxValues)}
              />
            )}
          />
        </form>
        <form autoComplete="off" className="flex-row form-row flex-gap">
          <Autocomplete
            options={fieldOptions}
            onChange={(event, values: AutocompleteOption[]) => setEmailValues(values)}
            value={emailValues}
            fullWidth
            multiple
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={compareOptionToValue}
            renderInput={(params) => (
              <TextField
                {...params} label={t("institution.addresses.email")}
                onBlur={createOnBlurEvent(setEmailValues, emailValues)} />
            )}
          />
          <Autocomplete
            options={fieldOptions}
            onChange={(event, values: AutocompleteOption[]) => setWebsiteValues(values)}
            value={websiteValues}
            fullWidth
            multiple
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={compareOptionToValue}
            renderInput={(params) => (
              <TextField
                {...params} label={t("institution.addresses.website")}
                onBlur={createOnBlurEvent(setWebsiteValues, websiteValues)}
              />
            )}
          />
        </form>
        <form autoComplete="off" className="flex-row form-row flex-gap">
          <Autocomplete
            options={fieldOptions}
            onChange={(event, values: AutocompleteOption[]) => setTypeValues(values)}
            value={typeValues}
            fullWidth
            multiple
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={compareOptionToValue}
            renderInput={(params) => (
              <TextField
                {...params} label={t("institutions.type")} onBlur={createOnBlurEvent(setTypeValues, typeValues)} />
            )}
          />
        </form>
      </DialogScrollBody>
    </BaseDialog>
  );
};

export default memo(InstitutionImportMappingDialog);

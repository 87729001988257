import { GlobalState } from "@nbp/dnafe-material-ui/dist/hooks/GlobalState";
import { getGlobalStateHookSetterGetter, getRequestHooks } from "@nbp/dnafe-material-ui/dist/hooks/globalStateHooks";
import { PagedProps, PagedResponse } from "@nbp/dnafe-material-ui/dist/models/paged";
import { ROWS_PER_PAGE } from "@nbp/dnafe-material-ui/dist/constants/usability";
import {
  AddressRequest,
  ApprovalProcessResponseDto,
  InstitutionBaseResponse,
  InstitutionDetailedResponse,
  InstitutionMerge,
  InstitutionRequest,
  institutionsGet,
  institutionsIdDelete,
  InstitutionsIdDeleteArgs,
  institutionsIdGet,
  InstitutionsIdGetArgs,
  institutionsIdPut,
  institutionsImportPost,
  institutionsInstitutionIdAdminsMemberIdApprovalPost,
  institutionsInstitutionIdAdminsMemberIdDelete,
  InstitutionsInstitutionIdAdminsMemberIdDeleteArgs,
  institutionsInstitutionIdAdminsPost,
  InstitutionsInstitutionIdAdminsPostArgs,
  institutionsInstitutionIdIssuersMemberIdApprovalPost,
  InstitutionsInstitutionIdIssuersMemberIdApprovalPostArgs,
  institutionsInstitutionIdIssuersMemberIdDelete,
  InstitutionsInstitutionIdIssuersMemberIdDeleteArgs,
  institutionsInstitutionIdIssuersPost,
  InstitutionsInstitutionIdIssuersPostArgs,
  institutionsInstitutionIdOperatingStatusPut,
  InstitutionsInstitutionIdOperatingStatusPutArgs,
  institutionsInstitutionIdSplitPost,
  InstitutionsInstitutionIdSplitPostArgs,
  institutionsMergePost, InstitutionSplitDto,
  institutionsPost,
  InstitutionStatusRequest,
  InstitutionUserResponse
} from "../api";
import { userMeGlobalState } from "./user";
import { FORMAT_DATE_SERVER } from "../constants/date";
import moment from "moment";

interface InstitutionListPagedProps extends PagedProps {
  sort?: string;
  issuerConfigId?: string;
  registrationAuthorityId?: string;
}

const institutionsLoadedForState: GlobalState<string> = new GlobalState(null);
export const [useInstitutionsLoadedFor, setInstitutionsLoadedFor] = getGlobalStateHookSetterGetter(institutionsLoadedForState);

export const [getInstitutionsPaged, useInstitutionPagedLoading, useInstitutionPaged, , institutionsPagedGlobalState] =
  getRequestHooks<PagedResponse<InstitutionBaseResponse>, InstitutionListPagedProps>(
    ({ issuerConfigId, registrationAuthorityId, search, sort, page = 0, size = ROWS_PER_PAGE } = {}) =>
      institutionsGet({
        issuerConfigId,
        registrationAuthorityId,
        page,
        size,
        search,
        sort: sort ? [sort] : undefined
      }) as any
  );


const institutionActionsPopoverState = new GlobalState<{ anchor?: any }>({});
export const [useInstitutionActionsPopover, setInstitutionActionsPopover, getInstitutionActionsPopover] = getGlobalStateHookSetterGetter(institutionActionsPopoverState);

const institutionsPopoverMenuSubject: GlobalState<{
  anchor: any,
  data?: InstitutionBaseResponse
}> = new GlobalState(null);
export const [useInstitutionsMenuPopover, setInstitutionsMenuPopover] = getGlobalStateHookSetterGetter(institutionsPopoverMenuSubject);

export interface MemberMenuPopover {
  anchor: any;
  data?: InstitutionUserResponse;
}

const InstitutionAdminMenuSubject: GlobalState<MemberMenuPopover> = new GlobalState(null);
export const [useInstitutionAdminMenuPopover, setInstitutionAdminMenuPopover] = getGlobalStateHookSetterGetter(InstitutionAdminMenuSubject);

const InstitutionIssuerMenuSubject: GlobalState<MemberMenuPopover> = new GlobalState(null);
export const [useInstitutionIssuerMenuPopover, setInstitutionIssuerMenuPopover] = getGlobalStateHookSetterGetter(InstitutionIssuerMenuSubject);

export interface AddressMenuPopover {
  anchor: any;
  data?: AddressRequest;
}

const InstitutionAddressMenuSubject: GlobalState<AddressMenuPopover> = new GlobalState(null);
export const [useInstitutionAddressMenuPopover, setInstitutionAddressMenuPopover] = getGlobalStateHookSetterGetter(InstitutionAddressMenuSubject);

export const [createInstitution, useCreateInstitutionLoading] =
  getRequestHooks<InstitutionBaseResponse, InstitutionRequest>(institutionsPost);

export const [mergeInstitutions, useMergeInstitutionsLoading] =
  getRequestHooks<any, InstitutionMerge>(institutionsMergePost);

interface SplitInstitutionRequest {
  args: InstitutionsInstitutionIdSplitPostArgs;
  body: InstitutionSplitDto;
}

export const [splitInstitution, useSplitInstitutionLoading] =
  getRequestHooks<any, SplitInstitutionRequest>(({args, body}: SplitInstitutionRequest) => institutionsInstitutionIdSplitPost(args, body));

export const [importInstitution, useImportInstitutionLoading] =
  getRequestHooks<InstitutionBaseResponse, InstitutionRequest>(institutionsImportPost);

export const [deleteInstitution, useDeleteInstitutionLoading] =
  getRequestHooks<any, InstitutionsIdDeleteArgs>(institutionsIdDelete);

interface UpdateInstitutionOperatingStatusRequest {
  args: InstitutionsInstitutionIdOperatingStatusPutArgs;
  body: InstitutionStatusRequest;
}

export const [updateInstitutionOperatingStatus, useUpdateInstitutionOperatingStatusLoading] =
  getRequestHooks<any, UpdateInstitutionOperatingStatusRequest>(
    ({ args, body }) => institutionsInstitutionIdOperatingStatusPut(args, body)
  );

interface InstitutionUpdateRequest {
  id: string;
  body: InstitutionRequest;
}

export const [updateInstitution, useUpdateInstitutionLoading] =
  getRequestHooks<InstitutionBaseResponse, InstitutionUpdateRequest>(
    ({ id, body }) => institutionsIdPut({ id }, { ...body, id: undefined } as any)
  );

export const [getInstitution, useInstitutionLoading, useInstitutionData] =
  getRequestHooks<InstitutionDetailedResponse, InstitutionsIdGetArgs>(institutionsIdGet);

interface InstitutionsAdminCreateAdminArgs {
  args: InstitutionsInstitutionIdAdminsPostArgs;
  body: InstitutionUserResponse;
}

export const [createInstitutionAdmin, useInstitutionAdminLoading] =
  getRequestHooks<InstitutionUserResponse, InstitutionsAdminCreateAdminArgs>(
    ({ args, body }) => institutionsInstitutionIdAdminsPost(args, body)
  );

export const [deleteInstitutionAdmin, useDeleteInstitutionAdminLoading] =
  getRequestHooks<any, InstitutionsInstitutionIdAdminsMemberIdDeleteArgs>(institutionsInstitutionIdAdminsMemberIdDelete);

interface InstitutionsMemberApprovalPostArgs {
  args: InstitutionsInstitutionIdIssuersMemberIdApprovalPostArgs;
  body: ApprovalProcessResponseDto;
}

export const [approveInstitutionAdmin, useApproveInstitutionAdminLoading] =
  getRequestHooks<any, InstitutionsMemberApprovalPostArgs>(
    ({ args, body }) => institutionsInstitutionIdAdminsMemberIdApprovalPost(args, body)
  );


interface InstitutionsIssuerCreateAdminArgs {
  args: InstitutionsInstitutionIdIssuersPostArgs;
  body: InstitutionUserResponse;
}

export const [createInstitutionIssuer, useInstitutionIssuerLoading] =
  getRequestHooks<InstitutionUserResponse, InstitutionsIssuerCreateAdminArgs>(
    ({ args, body }) => institutionsInstitutionIdIssuersPost(args, body)
  );

export const [deleteInstitutionIssuer, useDeleteInstitutionIssuerLoading] =
  getRequestHooks<any, InstitutionsInstitutionIdIssuersMemberIdDeleteArgs>(institutionsInstitutionIdIssuersMemberIdDelete);

export const [approveInstitutionIssuer, useApproveInstitutionIssuerLoading] =
  getRequestHooks<any, InstitutionsMemberApprovalPostArgs>(
    ({ args, body }) => institutionsInstitutionIdIssuersMemberIdApprovalPost(args, body)
  );

export const getMyAdminInstitution = (institutionId: string) => {
  const userMe = userMeGlobalState.getValue()?.data;
  return userMe?.institutionAdmins?.find(item => item.institution.id === institutionId);
};

export const hasInstitutionAdminAccess = (institutionId: string) => !!getMyAdminInstitution(institutionId);

export const getMyIssuerInstitution = (institutionId: string) => {
  const userMe = userMeGlobalState.getValue()?.data;
  return userMe?.institutionIssuers?.find(item => item.institution.id === institutionId);
};

export const hasInstitutionIssuersAccess = (institutionId: string) => !!getMyIssuerInstitution(institutionId);

const importInstitutionsTableDataSubject: GlobalState<{ [key: string]: string | number | any }[]> = new GlobalState([]);
export const [useImportInstitutionsTableData, setImportInstitutionsTableData, getImportInstitutionsTableData] = getGlobalStateHookSetterGetter(importInstitutionsTableDataSubject);

export const deleteImportInstitutionsTableData = (id: number, registrationAuthorityId: string) => {
  setImportInstitutionsTableData([...getImportInstitutionsTableData()].filter(
    item => !(item.id === id && item.registrationAuthorityId === registrationAuthorityId)
  ));
};

interface ImportProgressResult {
  record: any;
  success: boolean;
  time: number;
}

interface ImportProgress {
  data: any[];
  index: number;
  results: ImportProgressResult[];
}

const importInstitutionsProgressSubject: GlobalState<ImportProgress> = new GlobalState(null);
export const [useImportInstitutionsProgress, setImportInstitutionsProgress, getImportInstitutionsProgress] = getGlobalStateHookSetterGetter(importInstitutionsProgressSubject);

export const initImportLoop = (tableData: any) => {
  setImportInstitutionsProgress({
    data: tableData,
    index: 0,
    results: []
  });
  importLoop();
};

export const abortImportLoop = () => {
  setImportInstitutionsProgress(null);
};

const handleImportResponse = (record: any, success: boolean, requestTime: number) => {
  if (success) {
    deleteImportInstitutionsTableData(record.id, record.registrationAuthorityId);
  }
  const importInstitutionsProgress = getImportInstitutionsProgress();
  if (!importInstitutionsProgress) {
    return;
  }

  const responseTime = new Date().getTime();
  const lastResult = { success, record, time: responseTime - requestTime };
  setImportInstitutionsProgress({
    ...importInstitutionsProgress,
    index: importInstitutionsProgress.index + 1,
    results: [...importInstitutionsProgress.results, lastResult]
  });
  importLoop();
};

const importLoop = () => {
  const importInstitutionsProgress = getImportInstitutionsProgress();
  if (!importInstitutionsProgress) {
    return;
  }
  const { data, index } = importInstitutionsProgress;
  if (index === data.length) {
    return;
  }

  const record = data[index];

  const requestTime = new Date().getTime();
  importInstitution({
    ...record,
    id: undefined,
    openingDate: moment().format(FORMAT_DATE_SERVER)
  }).then(() => {
    handleImportResponse(record, true, requestTime);
  }).catch(error => {
    console.error(error);
    handleImportResponse(record, false, requestTime);
  });
};

import * as React from "react";
import { useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { hasAnyPermission, hasPermission, useCurrentUser } from "../hooks/auth";
import KeycloakLoading from "./KeycloakLoading/KeycloakLoading";
import { login, useKeycloak } from "../keycloak";
import { getUserMe, useUserMeData, useUserMeLoading } from "../hooks/user";

interface ProtectedRouteProps {
  permission?: string;
  anyPermissions?: string[];
  children?: any;
}

export const ProtectedRoute = ({ permission, anyPermissions, children }: ProtectedRouteProps) => {
  const ref = useRef(false);
  const { permissions, token } = useCurrentUser();
  const { keycloak, initialized } = useKeycloak();
  const { authenticated } = keycloak;
  const userMe = useUserMeData();
  const userMeLoading = useUserMeLoading();


  useEffect(() => {
    if (!token && !ref.current && initialized && !authenticated) {
      ref.current = true;
      login();
    }
  }, [token, initialized, authenticated]);

  useEffect(() => {
    if (token && !userMe && !userMeLoading) {
      getUserMe().catch(console.error);
    }
  }, [token, userMe, userMeLoading]);

  if (!authenticated && !initialized) {
    return (
      <KeycloakLoading />
    );
  }

  if (!authenticated) {
    return null;
  }

  if (permissions) {
    const singleRoleFailed = !!permission && !hasPermission(permission);
    const anyRoleFailed = !!anyPermissions && !hasAnyPermission(anyPermissions);

    if (singleRoleFailed || anyRoleFailed) {
      return <Navigate to="/" replace />;
    }
  }

  return children;
};

import React, { memo, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "mui-modal-provider";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import { ConfirmationDialog } from "@nbp/dnafe-material-ui/dist/components";
import PopoverMenu, { PopoverMenuItemProps } from "@nbp/dnafe-material-ui/dist/components/PopoverMenu";
import { SimpleDialogProps } from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import {
  setRegistrationAuthorityMenuPopover,
  useRegistrationAuthorityMenuPopover
} from "../../hooks/registrationAuthority";

interface RegistrationAuthorityMenuPopoverProps {
  onDelete: (id: string) => void;
}

export const RegistrationAuthorityMenuPopover = ({ onDelete }: RegistrationAuthorityMenuPopoverProps) => {
  const { t, i18n: { language } } = useTranslation();
  const popover = useRegistrationAuthorityMenuPopover();
  const anchor = popover?.anchor;
  const data = popover?.data;
  const { showModal } = useModal();

  const closeMenu = useEvent(() => setRegistrationAuthorityMenuPopover({ anchor: null, data }));
  useEffect(closeMenu, []);

  const deleteHandler = useEvent(() => onDelete(data.id));

  const showDeleteConfirmationDialog = useEvent(() => showModal((props: SimpleDialogProps) => (
    <ConfirmationDialog
      text={t("registrationAuthority.deleteConfirmation")} confirmText={t("main.delete")}
      title={t("main.confirmation")} cancelText={t("main.cancel")} onConfirm={deleteHandler} {...props}
    />
  )));

  const menuItems = useMemo<PopoverMenuItemProps[]>(() => [
    {
      text: t("main.delete"),
      onClick: showDeleteConfirmationDialog
    }
  ], [data, language]);

  return (
    <PopoverMenu
      className="RegistrationAuthorityMenuPopover"
      anchor={anchor}
      data={data}
      onClose={closeMenu}
      menuItems={menuItems}
    />
  );
};

export default memo(RegistrationAuthorityMenuPopover);

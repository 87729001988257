import { FormField } from "@nbp/dnafe-material-ui/dist/components";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import React, { memo, useMemo } from "react";
import { Control } from "react-hook-form";
import {
  VALIDATION_DATE_RANGE,
  VALIDATION_MAX_DATE,
  VALIDATION_MIN_DATE,
  VALIDATION_REQUIRED
} from "../../../constants/form";

interface DateRangeProps {
  control: Control<any, any>;
  getValues: () => any;
  startDateLabel: string;
  endDateLabel?: string;
  startDateHint?: string;
  endDateHint?: string;
  startDateFieldName?: string;
  endDateFieldName?: string;
  minDate?: string;
  maxDate?: string;
  startRequired?: boolean;
  endRequired?: boolean;
  onChange?: () => void;
}

const DateRange = (
  {
    control,
    getValues,
    startDateLabel,
    endDateLabel,
    startDateHint,
    endDateHint,
    minDate,
    maxDate,
    startRequired,
    endRequired,
    startDateFieldName = "startDate",
    endDateFieldName = "endDate",
    onChange
  }: DateRangeProps
) => {

  const onInputChange = useEvent((event: Event) => {
    typeof onChange === "function" && setTimeout(onChange, 0);
  });

  const inputPropsMemo = useMemo(() => ({ onChange: onInputChange, min: minDate, max: maxDate }), []);

  return (
    <div className="DateRange flex-row form-row flex-gap">
      <FormField
        control={control} name={startDateFieldName} type="date" label={startDateLabel ?? ""}
        inputProps={inputPropsMemo}
        className="flex-1" fullWidth
        rules={{
          validate: (startDate: string) => {
            const values = getValues();
            const endDate = values[endDateFieldName];
            if (startRequired && !startDate && endDate) {
              return VALIDATION_REQUIRED;
            }
            if (startDate && minDate && minDate > startDate) {
              return VALIDATION_MIN_DATE;
            }
            if (startDate && endDate && startDate > endDate) {
              return VALIDATION_DATE_RANGE;
            }
            return true;
          }
        }}
        hint={startDateHint}
      />
      <FormField
        control={control} name={endDateFieldName} type="date" label={endDateLabel ?? ""} className="flex-1"
        inputProps={inputPropsMemo}
        rules={{
          validate: (endDate: string) => {
            const values = getValues();
            const startDate = values[startDateFieldName];
            if (endRequired && !endDate && startDate) {
              return VALIDATION_REQUIRED;
            }
            if (endDate && maxDate && maxDate < endDate) {
              return VALIDATION_MAX_DATE;
            }
            if (startDate && endDate && startDate > endDate) {
              return VALIDATION_DATE_RANGE;
            }
            return true;
          }
        }}
        hint={endDateHint} fullWidth
      />
    </div>
  );
};

export default memo(DateRange);

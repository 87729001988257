import Papa from "papaparse";

export const loadUserFile = (file: File) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsText(file, "UTF-8");
  reader.onload = function(event) {
    resolve(String(event.target.result));
  };
  reader.onerror = function(event) {
    reject(event);
  };
});

export const parseCSV = (content: string) => {
  const records = Papa.parse(content.startsWith("sep=") ? content.substring(content.indexOf("\n") + 1) : content, {
    skipEmptyLines: true
  })?.data;
  const data: { [key: string]: string }[] = [];
  let fields: string[] = [];
  if (records?.length) {
    fields = records[0];
    records.forEach((row: string[], index: number) => {
      if (index) {
        const record: { [key: string]: string } = {};
        row.forEach((column: string, index: number) => {
          if (fields[index]) {
            record[fields[index]] = column;
          }
        });
        data.push(record);
      }
    });
  }
  return { data, fields };
};


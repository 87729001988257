import { LinearProgress, LinearProgressProps, Typography } from "@mui/material";
import React, { memo } from "react";

const progressStyle = { width: "50px" };

const ProgressBar = (props: LinearProgressProps & { value: number }) => {
  return (
    <div className="ProgressBar flex-row">
      <div className="flex-auto flex-column-justify-center">
        <LinearProgress variant="determinate" {...props} />
      </div>
      <Typography className="text-right" color="text.secondary" style={progressStyle}>
        {`${Math.floor(props.value)}%`}
      </Typography>
    </div>
  );
};

export default memo(ProgressBar);

import { EMAIL_PATTERN } from "../../constants/form";
import React, { FC, memo } from "react";
import { FormField } from "@nbp/dnafe-material-ui/dist/components";
import { useTranslation } from "react-i18next";

interface InstitutionAddressFieldsProps {
  control: any;
  readOnly?: boolean;
}

const InstitutionAddressFields: FC<InstitutionAddressFieldsProps> = ({ readOnly, control }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex-row form-row flex-gap">
        <FormField
          control={control} name="city" required label={t("institutions.city")} readOnly={readOnly} fullWidth
        />
        <FormField
          control={control} name="postalCode" required label={t("institutions.postalCode")}
          readOnly={readOnly} fullWidth
        />
      </div>
      <FormField
        control={control} name="street" required label={t("institutions.street")} readOnly={readOnly} fullWidth
      />
      <div className="flex-row form-row flex-gap">
        <FormField
          control={control} name="phone" label={t("institutions.phone")} readOnly={readOnly} fullWidth
        />
        <FormField
          control={control} name="fax" label={t("institutions.fax")} readOnly={readOnly} fullWidth
        />
      </div>
      <div className="flex-row form-row flex-gap">
        <FormField
          control={control} name="email" label={t("institutions.email")} readOnly={readOnly} fullWidth
          rules={{ pattern: { value: EMAIL_PATTERN } }}
        />
        <FormField
          control={control} name="website" label={t("institutions.website")} readOnly={readOnly} fullWidth
        />
      </div>
    </>
  );
};

export default memo(InstitutionAddressFields);

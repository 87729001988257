import { Button } from "@mui/material";
import React, { FC, memo, useId } from "react";
import { useTranslation } from "react-i18next";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import { BaseDialog, DialogScrollBody } from "@nbp/dnafe-material-ui/dist/components";
import { SimpleDialogProps } from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import { InstitutionBaseResponse, InstitutionRequest } from "../../api";
import { InstitutionRequestData } from "../../models/institution";
import InstitutionDetailsForm from "./InstitutionDetailsForm";
import { useForm } from "react-hook-form";
import { getInstitutionDataValues, getInstitutionDefaultValues } from "../../helpers/institution";
import { importInstitution, useImportInstitutionLoading } from "../../hooks/institution";
import moment from "moment/moment";
import { FORMAT_DATE_SERVER } from "../../constants/date";
import { showError, showSuccess } from "@nbp/dnafe-material-ui/dist/hooks/snackbar";

interface InstitutionImportDetailsDialogProps extends SimpleDialogProps {
  data?: InstitutionBaseResponse;
  readOnly?: boolean;
  onChange?: (id: number | string, data: InstitutionRequestData) => void;
  onUpdate?: () => void;
  registrationAuthorityId: string;
}

const InstitutionImportDetailsDialog: FC<InstitutionImportDetailsDialogProps> = (
  {
    onClose,
    open,
    data,
    onChange,
    onUpdate,
    readOnly,
    registrationAuthorityId
  }
) => {
  const { t } = useTranslation();
  const importInstitutionLoading = useImportInstitutionLoading();
  const formId = useId();
  const isEdit = !!data;

  const { control, handleSubmit } = useForm({
    defaultValues: isEdit ? getInstitutionDataValues(data) : getInstitutionDefaultValues()
  });

  const handleChange = useEvent((values: InstitutionRequest) => {
    const body = new InstitutionRequestData({ ...values, registrationAuthorityId });
    onChange(data?.id, body);
    onClose();
  });

  const successHandler = () => {
    showSuccess(t("institutions.dialog.saveSuccess"));
    onUpdate();
    onClose();
  };

  const errorHandler = (error: Error) => showError(error.message);

  const onSubmit = useEvent(async (values: InstitutionRequest) => {
    const body = new InstitutionRequestData({
      ...values,
      registrationAuthorityId,
      openingDate: moment().format(FORMAT_DATE_SERVER)
    });
    await importInstitution(body).then(successHandler).catch(errorHandler);
  });

  const loading = importInstitutionLoading;

  return (
    <BaseDialog
      className="InstitutionAddDialog" open={open} onClose={onClose} title={t("institutions.dialog.importTitle")}
      loading={loading} closeOnBackdropClick={!loading} closeOnEscapeKeyDown={!loading} size="medium"
      actions={(<>
        <Button
          variant="outlined" size="large" onClick={onClose} disabled={loading} autoFocus={isEdit}>
          {t(readOnly ? "main.close" : "main.cancel")}
        </Button>
        {!readOnly &&
          <Button
            variant="contained" size="large" form={formId} onClick={handleSubmit(handleChange)} name="change"
            disabled={loading || !open}
          >
            {t("main.change")}
          </Button>
        }
      </>)}>
      <DialogScrollBody>
        <InstitutionDetailsForm
          formId={formId} onSubmit={onSubmit} readOnly={readOnly} data={data} control={control}
          handleSubmit={handleSubmit} noDates
        />
      </DialogScrollBody>
    </BaseDialog>
  );
};

export default memo(InstitutionImportDetailsDialog);

import { GlobalState } from "@nbp/dnafe-material-ui/dist/hooks/GlobalState";
import { getGlobalStateHookSetterGetter, getRequestHooks } from "@nbp/dnafe-material-ui/dist/hooks/globalStateHooks";
import { PagedProps, PagedResponse } from "@nbp/dnafe-material-ui/dist/models/paged";
import { ROWS_PER_PAGE } from "@nbp/dnafe-material-ui/dist/constants/usability";
import {
  registrationAuthoritiesGet,
  registrationAuthoritiesIdDelete,
  RegistrationAuthoritiesIdDeleteArgs,
  registrationAuthoritiesIdGet,
  RegistrationAuthoritiesIdGetArgs,
  registrationAuthoritiesPost,
  registrationAuthoritiesRegistrationAuthorityIdAdminsGet,
  RegistrationAuthoritiesRegistrationAuthorityIdAdminsGetArgs,
  registrationAuthoritiesRegistrationAuthorityIdAdminsIdDelete,
  RegistrationAuthoritiesRegistrationAuthorityIdAdminsIdDeleteArgs,
  registrationAuthoritiesRegistrationAuthorityIdAdminsPost,
  RegistrationAuthoritiesRegistrationAuthorityIdAdminsPostArgs,
  RegistrationAuthorityAdminRequest,
  RegistrationAuthorityAdminResponse,
  RegistrationAuthorityRequest,
  RegistrationAuthorityResponse
} from "../api";
import { userMeGlobalState } from "./user";

interface RegistrationAuthorityPagedProps extends PagedProps {
  sort?: string;
}

export const [getRegistrationAuthorityPaged, useRegistrationAuthorityPagedLoading, useRegistrationAuthorityPaged] =
  getRequestHooks<PagedResponse<RegistrationAuthorityResponse>, RegistrationAuthorityPagedProps>(
    ({ sort, page = 0, size = ROWS_PER_PAGE } = {}) =>
      registrationAuthoritiesGet({ page, size, sort: sort ? [sort] : undefined }) as any
  );

const registrationAuthorityPopoverMenuState: GlobalState<{
  anchor: any,
  data?: RegistrationAuthorityResponse
}> = new GlobalState(null);
export const [useRegistrationAuthorityMenuPopover, setRegistrationAuthorityMenuPopover] = getGlobalStateHookSetterGetter(registrationAuthorityPopoverMenuState);

export const [createRegistrationAuthority, useCreateRegistrationAuthorityLoading] =
  getRequestHooks<RegistrationAuthorityResponse, RegistrationAuthorityRequest>(registrationAuthoritiesPost);

export const [deleteRegistrationAuthority, useDeleteRegistrationAuthorityLoading] =
  getRequestHooks<any, RegistrationAuthoritiesIdDeleteArgs>(registrationAuthoritiesIdDelete);
export const [getRegistrationAuthority, useGetRegistrationAuthorityLoading, useGetRegistrationAuthorityData] =
  getRequestHooks<RegistrationAuthorityResponse, RegistrationAuthoritiesIdGetArgs>(registrationAuthoritiesIdGet);

const registrationAuthorityAdminsLoadedForState: GlobalState<string> = new GlobalState(null);
export const [useRegistrationAuthorityAdminsLoadedFor, setRegistrationAuthorityAdminsLoadedFor] = getGlobalStateHookSetterGetter(registrationAuthorityAdminsLoadedForState);

export const [getRegistrationAuthorityAdmins, useRegistrationAuthorityAdminsLoading, useRegistrationAuthorityAdmins] =
  getRequestHooks<PagedResponse<RegistrationAuthorityAdminResponse>, RegistrationAuthoritiesRegistrationAuthorityIdAdminsGetArgs>(
    (props) => registrationAuthoritiesRegistrationAuthorityIdAdminsGet(props) as any
  );

interface RegistrationAuthorityCreateAdminArgs {
  args: RegistrationAuthoritiesRegistrationAuthorityIdAdminsPostArgs;
  body: RegistrationAuthorityAdminRequest;
}

export const [createRegistrationAuthorityAdmin, useCreateRegistrationAuthorityAdminLoading, useCreateRegistrationAuthorityAdmin] =
  getRequestHooks<RegistrationAuthorityAdminResponse, RegistrationAuthorityCreateAdminArgs>(
    ({ args, body }) => registrationAuthoritiesRegistrationAuthorityIdAdminsPost(args, body)
  );

export const [deleteRegistrationAuthorityAdmin, useDeleteRegistrationAuthorityAdminLoading, useDeleteRegistrationAuthorityAdmin] =
  getRequestHooks<any, RegistrationAuthoritiesRegistrationAuthorityIdAdminsIdDeleteArgs>(registrationAuthoritiesRegistrationAuthorityIdAdminsIdDelete);

export const getMyRegistrationAuthority = (registrationAuthorityId: string) => {
  const userMe = userMeGlobalState.getValue()?.data;
  return userMe?.registrationAuthorityAdmins?.find(item => item.registrationAuthority.id === registrationAuthorityId);
};

export const hasRegistrationAuthorityAccess = (registrationAuthorityId: string) => !!getMyRegistrationAuthority(registrationAuthorityId);

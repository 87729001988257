import { GlobalState } from "@nbp/dnafe-material-ui/dist/hooks/GlobalState";
import { getGlobalStateHookSetterGetter } from "@nbp/dnafe-material-ui/dist/hooks/globalStateHooks";
import jwt_decode from "jwt-decode";
import { getValueByPath } from "../helpers/utils";

const currentUserToken = sessionStorage.getItem("token") ?? null;

interface CurrentUser {
  token?: string;
  user: { [key: string]: any };
  permissions?: string[];
  decodedToken?: { [key: string]: any };
}

const decodedToken = currentUserToken ? jwt_decode(currentUserToken) : null;

const currentUserSubject = new GlobalState<CurrentUser>({
  token: currentUserToken,
  user: null,
  permissions: getValueByPath(decodedToken, "resource_access.dna-cs-biv-service.roles"),
  decodedToken
});

export const [useCurrentUser, nextCurrentUser, getCurrentUser] = getGlobalStateHookSetterGetter(currentUserSubject);

const keycloakInitErrorSubject = new GlobalState<boolean>(false);
export const [useKeycloakInitError, nextKeycloakInitError] = getGlobalStateHookSetterGetter(keycloakInitErrorSubject);

export const setCurrentUserToken = (token: string = null, refreshToken: string = null, idToken: string = null, decodedToken: any = null) => {
  const currentUser = getCurrentUser();
  sessionStorage.setItem("token", token ?? "");
  sessionStorage.setItem("refreshToken", refreshToken ?? "");
  sessionStorage.setItem("idToken", idToken ?? "");
  nextCurrentUser({
    ...currentUser,
    token,
    decodedToken,
    permissions: getValueByPath(decodedToken, "resource_access.dna-cs-biv-service.roles")
  });
};

export const setCurrentUserData = (user: { [key: string]: any }) => {
  const currentUser = getCurrentUser();
  nextCurrentUser({ ...currentUser, user });
};

export const hasPermission = (permission: string) => {
  const { permissions } = getCurrentUser();
  return permissions?.includes(permission);
};

export const hasAnyPermission = (anyPermissions: string[]) => {
  const { permissions } = getCurrentUser();
  const permissionsByName: { [key: string]: boolean } = {};
  permissions?.forEach(permission => {
    permissionsByName[permission] = true;
  });
  return anyPermissions?.some(permission => permissionsByName[permission]);
};

import React, { memo } from "react";
import PageHeader from "../components/Layout/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import { PageContent } from "@nbp/dnafe-material-ui/dist/components";
import { usePageTitle } from "../hooks/usePageTitle";
import { useUserMeData } from "../hooks/user";
import { NavLink } from "react-router-dom";
import { LINK_REGISTRATION_AUTHORITIES } from "../constants/navigate";

const RegistrationAuthoritiesPage = () => {
  usePageTitle("menu.registrationAuthorities");
  const { t } = useTranslation();
  const userMe = useUserMeData();
  const { registrationAuthorityAdmins } = userMe ?? {};

  return (
    <div className="MyRegistrationAuthoritiesPage">
      <PageHeader title={t("menu.registrationAuthorities")} />
      <PageContent>

        <ul>{registrationAuthorityAdmins?.map(({ registrationAuthority: { id, name } }) =>
          (<li key={id}><NavLink to={LINK_REGISTRATION_AUTHORITIES + "/" + id}>{name}</NavLink></li>)
        )}</ul>

      </PageContent>
    </div>
  );
};

export default memo(RegistrationAuthoritiesPage);

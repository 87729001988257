export const STATE_BB = "BB";
export const STATE_BE = "BE";
export const STATE_BW = "BW";
export const STATE_BY = "BY";
export const STATE_HB = "HB";
export const STATE_HE = "HE";
export const STATE_HH = "HH";
export const STATE_MV = "MV";
export const STATE_NI = "NI";
export const STATE_NW = "NW";
export const STATE_RP = "RP";
export const STATE_SH = "SH";
export const STATE_SL = "SL";
export const STATE_SN = "SN";
export const STATE_ST = "ST";
export const STATE_TH = "TH";

export const STATES = [STATE_BB, STATE_BE, STATE_BW, STATE_BY, STATE_HB, STATE_HE, STATE_HH, STATE_MV, STATE_NI, STATE_NW, STATE_RP, STATE_SH, STATE_SL, STATE_SN, STATE_ST, STATE_TH];

import React, { memo, useCallback, useEffect, useMemo } from "react";
import PageHeader from "../components/Layout/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import {
  ConfirmationDialog,
  DataPagination,
  DataTableActionsColumn,
  LoadingOverlay,
  PageContent
} from "@nbp/dnafe-material-ui/dist/components";
import { usePageTitle } from "../hooks/usePageTitle";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { getMyRegistrationAuthority } from "../hooks/registrationAuthority";
import { LINK_PART_IMPORT, LINK_REGISTRATION_AUTHORITIES, LINK_ROOT } from "../constants/navigate";
import { useUserMeData } from "../hooks/user";
import {
  deleteInstitution,
  getInstitutionsPaged,
  setInstitutionsLoadedFor,
  setInstitutionsMenuPopover,
  updateInstitutionOperatingStatus,
  useDeleteInstitutionLoading,
  useInstitutionPaged,
  useInstitutionPagedLoading,
  useInstitutionsLoadedFor,
  useUpdateInstitutionOperatingStatusLoading
} from "../hooks/institution";
import { useModal } from "mui-modal-provider";
import { InstitutionBaseResponse } from "../api";
import InstitutionDetailsDialog from "../components/Institution/InstitutionDetailsDialog";
import { getInstitutionAddress } from "../helpers/institution";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import { usePagedTable } from "@nbp/dnafe-material-ui/dist/hooks/usePagedTable";
import { SimpleDialogProps } from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import DataTable, { DataTableColumn } from "@nbp/dnafe-material-ui/dist/components/DataTable/DataTable";
import { InstitutionMenuPopover } from "../components/Institution/InstitutionMenuPopover";
import { Button } from "@mui/material";
import {
  CLOSING_REASON_NONE,
  INSTITUTION_STATUS_CLOSED,
  INSTITUTION_STATUS_CLOSED_FOREVER,
  INSTITUTION_STATUS_OPEN
} from "../constants/institution";
import { useForm } from "react-hook-form";
import InstitutionCloseConfirmationDialog
  from "../components/Institution/InstitutionCloseConfirmationDialog/InstitutionCloseConfirmationDialog";
import { OperatingStatusTemplate } from "../components/Institution/InstitutionTemplates";
import { showError, showSuccess } from "@nbp/dnafe-material-ui/dist/hooks/snackbar";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";

const RegistrationAuthorityDetailsPage = () => {
  usePageTitle("menu.registrationAuthority");
  const { registrationAuthorityId } = useParams();
  const { t, i18n: { language } } = useTranslation();
  const userMe = useUserMeData();
  const navigate = useNavigate();

  const institutionsPagedLoading = useInstitutionPagedLoading();
  const deleteInstitutionLoading = useDeleteInstitutionLoading();
  const updateInstitutionOperatingStatusLoading = useUpdateInstitutionOperatingStatusLoading();
  const institutionsLoadedFor = useInstitutionsLoadedFor();
  const { showModal } = useModal();

  const registrationAuthority = useMemo(() => getMyRegistrationAuthority(registrationAuthorityId), [registrationAuthorityId, userMe]);

  const { control, getValues, setValue } = useForm({
    defaultValues: { closingReason: CLOSING_REASON_NONE }
  });

  useEffect(() => {
    if (userMe) {
      if (!registrationAuthority) {
        navigate(LINK_ROOT);
      } else {
        loader();
      }
    }
  }, [registrationAuthority, userMe]);

  const loader = useEvent(async () => await getInstitutionsPaged({
    size: pageSize, page, search, sort: sortValue, registrationAuthorityId
  }).then(() => {
    setInstitutionsLoadedFor(registrationAuthorityId);
  }).catch(console.error));

  const { page, setPage, pageSize, search, setSearch, pages, tableRows, sort, onSort } =
    usePagedTable<InstitutionBaseResponse>({
      useData: useInstitutionPaged,
      useLoading: useInstitutionPagedLoading,
      loader,
      defaultSort: { field: "name", desc: false }
    });

  const tableData = institutionsLoadedFor === registrationAuthorityId ? tableRows : [];
  const sortValue = sort?.field ? [sort?.field, sort?.desc ? "desc" : "asc"].join(",") : undefined;

  const showAddInstitutionDialog = useEvent(() => showModal((props: SimpleDialogProps) => (
    <InstitutionDetailsDialog {...props} onUpdate={loader} registrationAuthorityId={registrationAuthorityId} />
  )));

  const showEditInstitutionDialog = useEvent((data: InstitutionBaseResponse) => showModal((props: SimpleDialogProps) => (
    <InstitutionDetailsDialog
      {...props} onUpdate={loader} data={data} registrationAuthorityId={registrationAuthorityId} />
  )));

  const updateOperatingStatus = useEvent(async (institutionId: string, operatingStatus: "OPEN" | "CLOSED") =>
    await updateInstitutionOperatingStatus({
      args: { institutionId },
      body: { operatingStatus, closingReason: getValues()?.closingReason }
    }).then(() => {
      showSuccess(t("institutions.dialog.saveSuccess"));
      loader();
    }).catch(error => showError(error.message)));

  const showOpenConfirmation = useEvent((data: InstitutionBaseResponse) => showModal((props: SimpleDialogProps) => (
    <ConfirmationDialog
      text={t("institutions.openConfirmation")} confirmText={t("institutions.openInstitution")}
      title={t("main.confirmation")} cancelText={t("main.cancel")}
      onConfirm={() => updateOperatingStatus(data.id, INSTITUTION_STATUS_OPEN)} {...props}
    />
  )));

  const showCloseConfirmation = useEvent((data: InstitutionBaseResponse) => {
    setValue("closingReason", CLOSING_REASON_NONE);
    showModal((props: SimpleDialogProps) => (<InstitutionCloseConfirmationDialog
      control={control} onConfirm={() => updateOperatingStatus(data.id, INSTITUTION_STATUS_CLOSED)} {...props}
    />));
  });

  const showPermanentlyCloseConfirmation = useEvent((data: InstitutionBaseResponse) => {
    showModal((props: SimpleDialogProps) => (<ConfirmationDialog
      text={t("institutions.permanentlyCloseConfirmation")} confirmText={t("institutions.permanentlyClose")}
      title={t("main.confirmation")} cancelText={t("main.cancel")}
      onConfirm={() => updateOperatingStatus(data.id, INSTITUTION_STATUS_CLOSED_FOREVER)} {...props}
    />));
  });

  const handleRowClick = useEvent((data: InstitutionBaseResponse) => {
    navigate(LINK_REGISTRATION_AUTHORITIES + "/" + data.registrationAuthority.id + "/" + data.id);
  });

  const deleteHandler = useEvent(async (id: string) => await deleteInstitution({ id }).then(() => {
    showSuccess(t("institutions.deleteSuccess"));
    loader();
  }).catch(error => {
    if (error?.response?.status === 400) {
      showError(t("institutions.deleteError"));
    } else {
      showError(error.message);
    }
  }));

  const DataTableActionsColumnTemplate = useCallback((row: InstitutionBaseResponse) => (
    <DataTableActionsColumn row={row} openMenuPopover={setInstitutionsMenuPopover} />
  ), [language]);

  const columns: DataTableColumn<InstitutionBaseResponse>[] = useMemo(() => [
    {
      name: "name",
      title: t("institutions.name"),
      sortable: true
    },
    {
      name: "type",
      title: t("institutions.type"),
      sortable: true
    },
    {
      name: "operatingStatus",
      title: t("institutions.operatingStatus"),
      sortable: true,
      template: OperatingStatusTemplate
    },
    {
      name: "countryCode",
      title: t("institutions.address"),
      template: (row: InstitutionBaseResponse) => getInstitutionAddress(row, t)
    },
    {
      name: "actions",
      className: "action-column",
      headerClassName: "action-column-header",
      title: t("main.actions"),
      template: DataTableActionsColumnTemplate
    }
  ], [language]);

  const loading = institutionsPagedLoading || deleteInstitutionLoading || updateInstitutionOperatingStatusLoading;

  return (
    <LoadingOverlay className="RegistrationAuthorityDetailsPage" loading={loading} fullHeight>
      <PageHeader
        title={registrationAuthority?.registrationAuthority?.name ?? t("menu.registrationAuthority")}
        subTitleMuted={t("menu.registrationAuthority")}
      />
      <PageHeader
        subTitle={t("institutions.title")} searchPlaceholder={t("institutions.searchPlaceholder")} onSearch={setSearch}>
        <div className="flex-row flex-gap flex-wrap">
          <NavLink to={LINK_REGISTRATION_AUTHORITIES + "/" + registrationAuthorityId + LINK_PART_IMPORT}>
            <Button startIcon={<PublishOutlinedIcon />} variant="outlined" color="primary">
              {t("institutions.importInstitutions")}
            </Button>
          </NavLink>
          <Button startIcon={<AddOutlinedIcon />} onClick={showAddInstitutionDialog} variant="outlined" color="primary">
            {t("institutions.addInstitution")}
          </Button>
        </div>
      </PageHeader>
      <PageContent>
        <DataTable
          data={tableData} columns={columns} onRowClick={handleRowClick} sort={sort} onSort={onSort}
          emptyText={t("main.empty")}
        />
        <DataPagination pages={pages || 0} onChange={setPage} value={page} />
      </PageContent>
      <InstitutionMenuPopover
        onDelete={deleteHandler} onEdit={showEditInstitutionDialog}
        onPermanentlyClose={showPermanentlyCloseConfirmation}
        onOpen={showOpenConfirmation} onClose={showCloseConfirmation}
      />
    </LoadingOverlay>
  );
};

export default memo(RegistrationAuthorityDetailsPage);

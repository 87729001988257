import React, { FC, memo, useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Control, Controller, UseFormHandleSubmit } from "react-hook-form";
import { AutocompleteOption } from "../../models/form";
import { InstitutionBaseResponse, InstitutionRequest } from "../../api";
import { FormField } from "@nbp/dnafe-material-ui/dist/components";
import { useTranslation } from "react-i18next";
import { STATES } from "../../constants/state";
import { VALIDATION_DATE_RANGE } from "../../constants/form";
import InstitutionAddressFields from "./InstitutionAddressFields";

const compareOptionToValue = (option: AutocompleteOption, value: AutocompleteOption) => {
  return option?.id === value?.id;
};

interface InstitutionDetailsFormProps {
  data?: InstitutionBaseResponse;
  formId: string;
  readOnly?: boolean;
  noDates?: boolean;
  onSubmit?: (values: InstitutionRequest) => void;
  control: Control<any, any>;
  handleSubmit: UseFormHandleSubmit<any>;
  getValues?: () => InstitutionRequest;
}

const InstitutionDetailsForm: FC<InstitutionDetailsFormProps> = (
  { formId, data, onSubmit, readOnly, control, handleSubmit, noDates, getValues }
) => {
  const { t, i18n: { language } } = useTranslation();
  const isEdit = !!data;

  const stateOptions: AutocompleteOption[] = useMemo(
    () => STATES.map((state) => ({ id: state, label: t("state." + state) })),
    [language]
  );

  const stateKey = "state." + data?.state;
  const stateTranslated = t(stateKey);
  const stateValue = stateKey === stateTranslated ? data?.state : stateTranslated;

  return (
    <form id={formId} onSubmit={!readOnly ? handleSubmit(onSubmit) : undefined} className="InstitutionDetailsForm">
      <FormField
        control={control} name="name" required label={t("institutions.name")} autoFocus={!isEdit && !readOnly}
        readOnly={readOnly} fullWidth
      />
      <div className="flex-row form-row flex-gap">
        {readOnly && <TextField
          type="text" fullWidth label={t("institutions.state")} disabled={readOnly} value={stateValue}
          className="FormField read-only"
        />}
        {!readOnly && <Controller
          control={control} name="state" rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Autocomplete
              options={stateOptions}
              onChange={(event, value: AutocompleteOption) => {
                onChange(value?.id);
              }}
              value={stateOptions.find(item => item.id === value) ?? null}
              disableClearable
              fullWidth
              isOptionEqualToValue={compareOptionToValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("institutions.state")}
                  error={!!error}
                  required
                  helperText={error
                    ? t("validation.required", { fieldName: t("institutions.state") })
                    : ""
                  }
                />
              )}
            />
          )} />}
      </div>
      <InstitutionAddressFields readOnly={readOnly} control={control} />
      <FormField
        control={control} name="type" required label={t("institutions.type")} readOnly={readOnly} fullWidth
      />
      {!noDates && <div className="flex-row form-row flex-gap">
        <FormField
          control={control} name="openingDate" required label={t("institutions.openingDate")} readOnly={readOnly}
          fullWidth type="date"
        />
        <FormField
          control={control} name="closingDate" label={t("institutions.closingDate")} readOnly={readOnly}
          rules={{
            validate: (closingDate: string) => {
              const values = getValues();
              const openingDate = values?.openingDate;
              if (closingDate && openingDate > closingDate) {
                return VALIDATION_DATE_RANGE;
              }
              return true;
            }
          }}
          fullWidth type="date"
        />
      </div>}
    </form>
  );
};

export default memo(InstitutionDetailsForm);

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import de from "./locales/de.json";
import { DEFAULT_LANGUAGE, LANGUAGE_DE, LANGUAGE_EN } from "./constants/language";
import "moment/locale/de";

const allowedLanguages = [LANGUAGE_EN, LANGUAGE_DE];

i18n.on("languageChanged", () => {
  if (!allowedLanguages.includes(i18n.language)) {
    i18n.changeLanguage(DEFAULT_LANGUAGE).then().catch(console.error);
  } else {
    setTimeout(() => {
      const { t } = i18n;
      const titleTranslationKey: any = i18n.store.data.titleTranslationKey;
      window.document.title = (titleTranslationKey ? t(titleTranslationKey) + " | " : "") + t("main.title");
      document.documentElement.lang = i18n.language.split("-")[0];
    }, 0);
  }
});

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      [LANGUAGE_EN]: {
        translation: en
      },
      [LANGUAGE_DE]: {
        translation: de
      }
    },
    fallbackLng: DEFAULT_LANGUAGE,
    debug: false,
    interpolation: {
      escapeValue: false
    }
  }).then().catch(console.error);

window.document.title = i18n.t("main.title");

export default i18n;

import i18n from "../i18n";
import {useEffect} from "react";

export const usePageTitle = (titleTranslationKey?: string) => {
  useEffect(() => {
    const {t} = i18n;
    i18n.store.data.titleTranslationKey = titleTranslationKey as any;
    window.document.title = (titleTranslationKey ? t(titleTranslationKey) + " | " : "") + t("main.title");
    return () => {
      delete i18n.store.data.titleTranslationKey;
      window.document.title = t("main.title");
    };
  }, []);
};

const {
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REDIRECT_URI,
  KEYCLOAK_REALM,
  KEYCLOAK_URL
} = window.env?.keycloak ?? {};

export const KEYCLOAK_CREDENTIALS = {
  REDIRECT_URI: KEYCLOAK_REDIRECT_URI,
  CLIENT_ID: KEYCLOAK_CLIENT_ID,
  URL: KEYCLOAK_URL,
  REALM: KEYCLOAK_REALM
};

export const REFRESH_TOKEN_MIN_VALIDITY = 30000;
export const REFRESH_TOKEN_UPDATE_TIME = 30000;

import { DataTable, DataTableActionsColumn } from "@nbp/dnafe-material-ui/dist/components";
import { DataTableColumn } from "@nbp/dnafe-material-ui/dist/components/DataTable/DataTable";
import React, { FC, memo, useCallback, useMemo } from "react";
import { AddressRequest, InstitutionUserResponse } from "../../api";
import { useTranslation } from "react-i18next";
import { MemberMenuPopover } from "../../hooks/institution";
import { InstitutionAddressMenuPopover } from "./InstitutionAddressMenuPopover";
import { getDependencyAddress } from "../../helpers/institution";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import { useModal } from "mui-modal-provider";
import { SimpleDialogProps } from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import InstitutionAddressViewDialog from "./InstitutionAddressViewDialog";

interface InstitutionAddressesTableProps {
  data: InstitutionUserResponse[];
  onDelete: (address: AddressRequest) => void;
  onEdit: (address: AddressRequest) => void;
  usePopover: () => MemberMenuPopover;
  setPopover: (popover: MemberMenuPopover) => void;
  readOnly?: boolean;
}

const InstitutionAddressesTable: FC<InstitutionAddressesTableProps> = (
  { data = [], onDelete, onEdit, usePopover, setPopover, readOnly }
) => {
  const { t, i18n: { language } } = useTranslation();
  const { showModal } = useModal();

  const DataTableActionsColumnTemplate = useCallback((row: InstitutionUserResponse) => (
    <DataTableActionsColumn row={row} openMenuPopover={setPopover} />
  ), [language]);

  const columns = useMemo(() => {
    const columns: DataTableColumn<InstitutionUserResponse>[] = [
      {
        name: "dependencyName",
        title: t("institution.addresses.dependencyName")
      },
      {
        name: "address",
        title: t("institution.addresses.address"),
        template: row => getDependencyAddress(row, t)
      },
      {
        name: "phone",
        title: t("institution.addresses.phone")
      },
      !readOnly && {
        name: "actions",
        className: "action-column",
        headerClassName: "action-column-header",
        title: t("main.actions"),
        template: DataTableActionsColumnTemplate
      }
    ];
    return columns.filter(item => !!item);
  }, [language, readOnly]);

  const onRowClick = useEvent((address: AddressRequest) => showModal((props: SimpleDialogProps) => (
    <InstitutionAddressViewDialog {...props} data={address} />
  )));

  return (
    <div className="InstitutionAddressesTable">
      <DataTable data={data} columns={columns} emptyText={t("main.empty")} onRowClick={readOnly ? onRowClick : null} />
      <InstitutionAddressMenuPopover
        onDelete={onDelete} usePopover={usePopover} setPopover={setPopover} onEdit={onEdit}
      />
    </div>
  );
};

export default memo(InstitutionAddressesTable);

import React, { memo, ReactElement } from "react";
import "./PageHeader.scss";
import SearchField from "../../SearchField/SearchField";

interface PageHeaderProps {
  title?: string | ReactElement;
  subTitle?: string | ReactElement;
  subTitleMuted?: string;
  onSearch?: (query: string) => void;
  searchPlaceholder?: string;
  children?: any;
}

const PageHeader = ({ title, subTitle, subTitleMuted, onSearch, searchPlaceholder, children }: PageHeaderProps) => {
  return (
    <div className={"PageHeader" + ((!title && subTitle) ? " subHeader": "")}>
      <div className="inner">
        <div className="page-header-title flex-column-justify-center">
          {!!title && <h1 className="text-title-1">{title}</h1>}
          {!!subTitle && <h2 className="text-title-2">{subTitle}</h2>}
          {!!subTitleMuted && <p className="text-muted">{subTitleMuted}</p>}
        </div>
        <div className="page-header-right">
          {!!onSearch &&
            <SearchField placeholder={searchPlaceholder} onSearch={onSearch} />
          }
          <div className="page-header-children">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PageHeader);

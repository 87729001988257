export const MEMBER_EXPIRE_SOON_DAYS = 7;

export const INSTITUTION_COUNTRY_CODE = "DE";

export const INSTITUTION_INVALID_REQUIRED = "INVALID_REQUIRED";
export const INSTITUTION_INVALID_DUPLICATE = "INVALID_DUPLICATE";

export const INSTITUTION_REQUIRED_FIELDS = ["name", "state", "type"];
export const ADDRESS_REQUIRED_FIELDS = ["city", "postalCode", "street"];

export const INSTITUTION_STATUS_OPEN = "OPEN";
export const INSTITUTION_STATUS_CLOSED = "CLOSED";
export const INSTITUTION_STATUS_CLOSED_FOREVER = "CLOSED_FOREVER";

export const CLOSING_REASON_NONE = "NONE";
export const CLOSING_REASON_MERGED = "MERGED";
export const CLOSING_REASON_SPLIT = "SPLIT";

export const SPLIT_MEMBER_KEEP = "KEEP";
export const SPLIT_MEMBER_MOVE = "MOVE";
export const SPLIT_MEMBER_COPY = "COPY";

export const ADDRESS_ROLE_MAIN = "MAIN";
export const ADDRESS_ROLE_DEPENDENCY = "DEPENDENCY";

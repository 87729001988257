import axios from "axios";

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

const { REACT_APP_DEV_X_MBR_SSO } = process.env;

axios.interceptors.request.use((config) => {
  const { url = "" } = config;
  if (!isAbsoluteURLRegex.test(url)) {
    const apiPath = window.env.API_PATH || "";
    config.url = `${apiPath}${url}`;
    console.debug("Backend API URL", config.url);
  }
  const token = sessionStorage.getItem("token");
  if (token && !config?.headers?.Authorization) {
    config.headers.Authorization = "Bearer " + token;
    if (REACT_APP_DEV_X_MBR_SSO) {
      config.headers["X-MBR-SSO"] = REACT_APP_DEV_X_MBR_SSO;
    }
  }
  return config;
});

import { GlobalState } from "@nbp/dnafe-material-ui/dist/hooks/GlobalState";
import { getGlobalStateHookSetterGetter } from "@nbp/dnafe-material-ui/dist/hooks/globalStateHooks";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { showError } from "@nbp/dnafe-material-ui/dist/hooks/snackbar";

export const breadcrumbValuesState: GlobalState<{ [key: string]: string }> = new GlobalState({});
export const [useBreadcrumbValues, setBreadcrumbValues, getBreadcrumbValues] = getGlobalStateHookSetterGetter(breadcrumbValuesState);

type AxiosErrorHandler = (error: AxiosError) => void;

export const useAxiosErrorHandler = (): AxiosErrorHandler => {
  const { t, i18n: { language } } = useTranslation();

  return useCallback((error: AxiosError) => {
    const status = error.response?.status;
    if (error.response?.status === 404) {
      console.error(error.response.data);
      showError(t("main.unavailableError"));
    } else if (status === 403) {
      showError(t("main.permissionError"));
    } else if (status >= 400) {
      console.error(error.response.data);
      showError(t("main.serverError"));
    } else if ((error.response?.data as any).message) {
      console.error(error);
      showError((error.response.data as any).message);
    } else {
      console.error(error);
      showError(error.message);
    }
  }, [language]);
};

import React, { FC, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog, FormField } from "@nbp/dnafe-material-ui/dist/components";
import { SimpleDialogProps } from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import { CLOSING_REASON_MERGED, CLOSING_REASON_NONE } from "../../../constants/institution";
import "./InstitutionCloseConfirmationDialog.scss";

interface InstitutionCloseConfirmationDialogProps extends SimpleDialogProps {
  control: any;
  onConfirm?: () => void;
}

const InstitutionCloseConfirmationDialog: FC<InstitutionCloseConfirmationDialogProps> = (
  { onClose, open, control, onConfirm }
) => {
  const { t, i18n: { language } } = useTranslation();
  const reasonOptions = useMemo(() => ([
    { id: CLOSING_REASON_NONE, name: t("institution.reason." + CLOSING_REASON_NONE) },
    { id: CLOSING_REASON_MERGED, name: t("institution.reason." + CLOSING_REASON_MERGED) }
  ]), [language]);

  return (
    <ConfirmationDialog
      text={t("institutions.closeConfirmation")} confirmText={t("institutions.closeInstitution")}
      title={t("main.confirmation")} cancelText={t("main.cancel")}
      onConfirm={onConfirm} open={open} onClose={onClose}
    >
      <div className="InstitutionCloseConfirmationDialog">
        <FormField
          fullWidth type="select" name="closingReason" control={control} label={t("institution.closingReason")}
          options={reasonOptions}
        />
      </div>
    </ConfirmationDialog>
  );
};

export default memo(InstitutionCloseConfirmationDialog);

import {deDE, enUS, Localization} from "@mui/material/locale";
import i18n from "../i18n";
import {LANGUAGE_EN} from "../constants/language";

export function getMUILocale(): Localization {
  if (i18n.language === LANGUAGE_EN) {
    return enUS;
  }
  return deDE;
}

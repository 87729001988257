import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "../hooks/auth";
import { logout, useKeycloak } from "../keycloak";
import { LINK_ROOT } from "../constants/navigate";

export default function LogoutPage(): null {
  const ref = useRef(false);
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { initialized } = useKeycloak();

  useEffect(() => {
    if (initialized && !ref.current) {
      ref.current = true;
      if (currentUser.token) {
        logout();
      } else {
        navigate(LINK_ROOT);
      }
    }
  }, [initialized]);

  return null;
}

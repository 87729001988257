import { Button } from "@mui/material";
import React, { FC, memo, useId } from "react";
import { useTranslation } from "react-i18next";
import {
  createInstitution,
  updateInstitution,
  useCreateInstitutionLoading,
  useUpdateInstitutionLoading
} from "../../hooks/institution";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import { BaseDialog, DialogScrollBody } from "@nbp/dnafe-material-ui/dist/components";
import { SimpleDialogProps } from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import { InstitutionBaseResponse, InstitutionRequest } from "../../api";
import { InstitutionRequestData } from "../../models/institution";
import InstitutionDetailsForm from "./InstitutionDetailsForm";
import { useForm } from "react-hook-form";
import { getInstitutionDataValues, getInstitutionDefaultValues } from "../../helpers/institution";
import { AxiosError } from "axios";
import { useAxiosErrorHandler } from "../../hooks/general";
import { showError, showSuccess } from "@nbp/dnafe-material-ui/dist/hooks/snackbar";

interface InstitutionDetailsDialogProps extends SimpleDialogProps {
  data?: InstitutionBaseResponse;
  readOnly?: boolean;
  onUpdate?: () => void;
  registrationAuthorityId: string;
}

const InstitutionDetailsDialog: FC<InstitutionDetailsDialogProps> = (
  {
    onClose,
    open,
    data,
    onUpdate,
    readOnly,
    registrationAuthorityId
  }
) => {
  const { t } = useTranslation();
  const createInstitutionLoading = useCreateInstitutionLoading();
  const updateInstitutionLoading = useUpdateInstitutionLoading();
  const formId = useId();
  const isEdit = !!data;
  const axiosErrorHandler = useAxiosErrorHandler();

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: isEdit ? getInstitutionDataValues(data) : getInstitutionDefaultValues()
  });

  const successHandler = () => {
    showSuccess(t("institutions.dialog.saveSuccess"));
    onClose();
    onUpdate();
  };

  const errorHandler = (error: AxiosError) => {
    if (error.response.status === 422) {
      showError(t("institution.nameUniqueError"));
    } else {
      axiosErrorHandler(error);
    }
  };

  const onSubmit = useEvent(async (values: InstitutionRequest) => {
    const body = new InstitutionRequestData({
      ...values,
      registrationAuthorityId
    });
    if (data?.addresses?.length) {
      data.addresses[0] = body.addresses[0];
      body.addresses = data.addresses;
    }
    if (isEdit) {
      await updateInstitution({ id: data.id, body }).then(successHandler).catch(errorHandler);
    } else {
      await createInstitution(body).then(successHandler).catch(errorHandler);
    }
  });

  const loading = createInstitutionLoading || updateInstitutionLoading;

  let title;
  if (!isEdit) {
    title = t("institutions.dialog.addTitle");
  } else {
    title = !readOnly ? t("institutions.dialog.editTitle") : t("institutions.dialog.viewTitle");
  }

  return (
    <BaseDialog
      className="InstitutionAddDialog" open={open} onClose={onClose} title={title}
      loading={loading} closeOnBackdropClick={!loading} closeOnEscapeKeyDown={!loading} size="medium"
      actions={(<>
        <Button
          variant="outlined" size="large" onClick={onClose} disabled={loading} autoFocus={isEdit}>
          {t(readOnly ? "main.close" : "main.cancel")}
        </Button>
        {!readOnly &&
          <Button
            variant="contained" size="large" form={formId} type="submit" disabled={loading || !open}>
            {t("main.save")}
          </Button>
        }
      </>)}>
      <DialogScrollBody>
        <InstitutionDetailsForm
          formId={formId} onSubmit={onSubmit} readOnly={readOnly} data={data} control={control}
          handleSubmit={handleSubmit} getValues={getValues}
        />
      </DialogScrollBody>
    </BaseDialog>
  );
};

export default memo(InstitutionDetailsDialog);

import { DataPagination, LoadingOverlay, PageContent } from "@nbp/dnafe-material-ui/dist/components";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../../components/Layout/PageHeader/PageHeader";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useTranslation } from "react-i18next";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import {
  getRegistrationAuthority,
  useGetRegistrationAuthorityData,
  useGetRegistrationAuthorityLoading
} from "../../hooks/registrationAuthority";
import { getBreadcrumbValues, setBreadcrumbValues } from "../../hooks/general";
import { getAnalysisPaged, useAnalysisPaged, useAnalysisPagedLoading } from "../../hooks/analisys";
import DateRange from "../../components/Layout/Form/DateRange";
import { FORMAT_DATE_SERVER } from "../../constants/date";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Alert, Button } from "@mui/material";
import "./RegistrationAuthorityAdministrationAnalysisPage.scss";
import { AnalysisResponse } from "../../api";
import { usePagedTable } from "@nbp/dnafe-material-ui/dist/hooks/usePagedTable";
import { PagedResponse } from "@nbp/dnafe-material-ui/dist/models/paged";
import DataTable, { DataTableColumn } from "@nbp/dnafe-material-ui/dist/components/DataTable/DataTable";
import SearchField from "../../components/SearchField/SearchField";

const getDefaultValues = () => ({
  startDate: moment().subtract(1, "month").startOf("month").format(FORMAT_DATE_SERVER),
  endDate: moment().startOf("month").subtract(1, "day").format(FORMAT_DATE_SERVER)
});

const RegistrationAuthorityAdministrationAnalysisPage = () => {
  usePageTitle("menu.analysis");
  const { registrationAuthorityId } = useParams();
  const { t, i18n: { language } } = useTranslation();

  const registrationAuthorityLoading = useGetRegistrationAuthorityLoading();
  const registrationAuthorityData = useGetRegistrationAuthorityData();
  const { name } = registrationAuthorityData ?? {};
  const defaultValues = getDefaultValues();
  const analysisPagedLoading = useAnalysisPagedLoading();
  const [error, setError] = useState(false);

  const { control, getValues, reset, trigger } = useForm({ defaultValues });

  const loader = useEvent(async () => {
    setError(false);
    try {
      const valid = await trigger().catch(console.error);
      if (valid) {
        const { startDate, endDate } = getValues();
        return await getAnalysisPaged({
          startDate,
          endDate,
          search,
          sort: sortValue ? [sortValue] : undefined,
          registrationAuthorityId,
          page,
          size: pageSize
        }).catch(console.error);
      }
    } catch (error) {
      console.error(error);
    }
    setError(true);
    return null;
  });

  const { page, setPage, pageSize, search, setSearch, pages, tableRows, sort, onSort } =
    usePagedTable<AnalysisResponse>({
      useData: useAnalysisPaged as () => PagedResponse<AnalysisResponse>,
      useLoading: useAnalysisPagedLoading,
      loader,
      defaultSort: { field: "name", desc: false }
    });

  const sortValue = sort?.field ? [sort?.field, sort?.desc ? "desc" : "asc"].join(",") : undefined;

  const setDateRange = useEvent((startDate: string, endDate: string) => {
    reset({ startDate, endDate });
    loader();
  });

  useEffect(() => {
    if (registrationAuthorityId) {
      getRegistrationAuthority({ id: registrationAuthorityId }).then(data => {
        if (data?.id) {
          setBreadcrumbValues({ ...getBreadcrumbValues(), [data.id]: data.name });
        }
      }).catch(console.error);
      loader();
    }
  }, [registrationAuthorityId]);


  const rangeOptions = useMemo(() => ([
    {
      label: t("registrationAuthority.analysis.range.allTime"),
      onClick: () => {
        setDateRange("1970-01-01", moment().format(FORMAT_DATE_SERVER));
      }
    },
    {
      label: t("registrationAuthority.analysis.range.previousYear"),
      onClick: () => {
        setDateRange(
          moment().subtract(1, "year").startOf("year").format(FORMAT_DATE_SERVER),
          moment().startOf("year").subtract(1, "day").format(FORMAT_DATE_SERVER)
        );
      }
    },
    {
      label: t("registrationAuthority.analysis.range.currentYear"),
      onClick: () => {
        setDateRange(moment().startOf("year").format(FORMAT_DATE_SERVER), moment().format(FORMAT_DATE_SERVER));
      }
    },
    {
      label: t("registrationAuthority.analysis.range.previousMonth"),
      onClick: () => {
        setDateRange(defaultValues.startDate, defaultValues.endDate);
      }
    },
    {
      label: t("registrationAuthority.analysis.range.currentMonth"),
      onClick: () => {
        setDateRange(moment().startOf("month").format(FORMAT_DATE_SERVER), moment().format(FORMAT_DATE_SERVER));
      }
    }
  ]), [language]);

  const columns: DataTableColumn<AnalysisResponse>[] = useMemo(() => [
    {
      name: "institution",
      title: t("registrationAuthority.analysis.institution"),
      sortable: true,
      sortField: "name",
      template: ({ institution: { name } }) => name
    },
    {
      name: "numberOfSignatures",
      headerClassName: "number-column-header",
      title: t("registrationAuthority.analysis.numberOfSignatures")
    },
    {
      name: "numberOfRevocations",
      headerClassName: "number-column-header",
      title: t("registrationAuthority.analysis.numberOfRevocations")
    }
  ], [language]);

  const loading = registrationAuthorityLoading || analysisPagedLoading;

  return (
    <LoadingOverlay className="RegistrationAuthorityAdministrationAnalysisPage" loading={loading}>
      <PageHeader title={name ?? t("menu.registrationAuthorityAdministration")} subTitleMuted={t("menu.analysis")}>
        <SearchField
          placeholder={t("registrationAuthority.analysis.search")} onSearch={setSearch} className="margin-bottom-s" />
      </PageHeader>
      <PageContent>
        <div className="filter-row flex-row flex-wrap flex-gap margin-bottom-m">
          <div className="date-range-column flex-auto flex-row flex-wrap flex-gap">
            <div>
              <DateRange
                control={control} maxDate={moment().format(FORMAT_DATE_SERVER)} minDate={"1970-01-01"} onChange={loader}
                getValues={getValues} startRequired endRequired
                startDateLabel={t("registrationAuthority.analysis.startDate")}
                endDateLabel={t("registrationAuthority.analysis.endDate")}
              />
            </div>
            <div className="flex-auto flex-row flex-wrap flex-gap">
              {rangeOptions.map(({ onClick, label }) => (
                <div key={label}><Button variant="text" onClick={onClick}>{label}</Button></div>
              ))}
            </div>
          </div>
        </div>
        {error && <Alert className="margin-bottom-m" color="error">
          {t("registrationAuthority.analysis.filterInvalid")}
        </Alert>}
        <DataTable data={tableRows} columns={columns} emptyText={t("main.empty")} sort={sort} onSort={onSort} />
        <DataPagination pages={pages || 0} onChange={setPage} value={page} />
      </PageContent>
    </LoadingOverlay>
  );
};

export default memo(RegistrationAuthorityAdministrationAnalysisPage);

import { DataTable, DataTableActionsColumn } from "@nbp/dnafe-material-ui/dist/components";
import { DataTableColumn } from "@nbp/dnafe-material-ui/dist/components/DataTable/DataTable";
import React, { FC, memo, useCallback, useMemo } from "react";
import { InstitutionUserResponse } from "../../api";
import { useTranslation } from "react-i18next";
import { FORMAT_DATE_CLIENT, FORMAT_DATE_TIME_SERVER } from "../../constants/date";
import moment from "moment";
import { InstitutionMemberMenuPopover } from "./InstitutionMemberMenuPopover";
import { MemberMenuPopover } from "../../hooks/institution";
import { Chip, Tooltip, Typography } from "@mui/material";
import i18n from "i18next";
import { MEMBER_EXPIRE_SOON_DAYS } from "../../constants/institution";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import AccessTimeFilledOutlinedIcon from "@mui/icons-material/AccessTimeFilledOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";

export const MemberStatusTemplate = ({ approvalState, endDate }: InstitutionUserResponse) => {
  const isExpired = !!endDate && moment(endDate, FORMAT_DATE_TIME_SERVER).isBefore(moment());
  const label = isExpired ? i18n.t("institution.member.stateExpired") : i18n.t("institution.member.state" + approvalState);
  const color = isExpired ? "error" : { 1: "default", 0: "success", "-1": "error" }[approvalState] as any;
  const icon = isExpired ? <AccessTimeFilledOutlinedIcon /> : {
    1: <AccessTimeOutlinedIcon />,
    0: <CheckCircleOutlinedIcon />,
    "-1": <AccessTimeFilledOutlinedIcon />
  }[approvalState];

  return (
    <Chip label={label} color={color} icon={icon} />
  );
};

export const ValidUntilTemplate = ({ endDate }: InstitutionUserResponse) => {
  const isExpireSoon = !!endDate &&
    moment(endDate, FORMAT_DATE_TIME_SERVER).isAfter(moment()) &&
    moment(endDate, FORMAT_DATE_TIME_SERVER).diff(moment(), "day") < MEMBER_EXPIRE_SOON_DAYS;

  return (
    <div className="flex-row flex-gap">
      <span>{endDate ? moment(endDate).utc().format(FORMAT_DATE_CLIENT) : ""}</span>
      {isExpireSoon && <Tooltip title={i18n.t("institution.member.expireSoonTooltip")}>
        <Typography color="warning.main"><WarningOutlinedIcon /></Typography>
      </Tooltip>}
    </div>
  );
};

interface InstitutionMembersTableProps {
  data: InstitutionUserResponse[];
  onDelete: (id: string) => void;
  onApprove: (id: string) => void;
  usePopover: () => MemberMenuPopover;
  setPopover: (popover: MemberMenuPopover) => void;
  readOnly?: boolean;
}

const InstitutionMembersTable: FC<InstitutionMembersTableProps> = (
  { data = [], onDelete, onApprove, usePopover, setPopover, readOnly }
) => {
  const { t, i18n: { language } } = useTranslation();

  const DataTableActionsColumnTemplate = useCallback((row: InstitutionUserResponse) => (
    <DataTableActionsColumn row={row} openMenuPopover={setPopover} />
  ), [language]);

  const columns = useMemo(() => {
    const columns: DataTableColumn<InstitutionUserResponse>[] = [
      {
        name: "email",
        title: t("institution.member.email")
      },
      {
        name: "approvalState",
        headerClassName: "column-created-at",
        title: t("institution.member.approvalState"),
        template: MemberStatusTemplate
      },
      {
        name: "startDate",
        headerClassName: "column-created-at",
        title: t("institution.member.startDate"),
        template: ({ startDate }) => startDate ? moment(startDate).utc().format(FORMAT_DATE_CLIENT) : ""
      },
      {
        name: "endDate",
        headerClassName: "column-created-at",
        title: t("institution.member.endDate"),
        template: ValidUntilTemplate
      },
      !readOnly && {
        name: "actions",
        className: "action-column",
        headerClassName: "action-column-header",
        title: t("main.actions"),
        template: DataTableActionsColumnTemplate
      }
    ];
    return columns.filter(item => !!item);
  }, [language, readOnly]);

  return (
    <div className="InstitutionMembersTable">
      <DataTable data={data} columns={columns} emptyText={t("main.empty")} />
      <InstitutionMemberMenuPopover
        onApprove={onApprove} onDelete={onDelete} usePopover={usePopover} setPopover={setPopover}
      />
    </div>
  );
};

export default memo(InstitutionMembersTable);

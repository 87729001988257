import { Button } from "@mui/material";
import React, { FC, memo, useEffect, useId } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import { BaseDialog, FormField } from "@nbp/dnafe-material-ui/dist/components";
import { SimpleDialogProps } from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import { useAxiosErrorHandler } from "../../hooks/general";
import { showSuccess } from "@nbp/dnafe-material-ui/dist/hooks/snackbar";
import { AddressRequest } from "../../api";
import InstitutionAddressFields from "./InstitutionAddressFields";

interface InstitutionAddressDialogProps extends SimpleDialogProps {
  title?: string;
  readOnly?: boolean;
  onUpdate?: () => void;
  useLoading?: () => boolean;
  saveHandler?: (values: any) => Promise<any>;
  data?: AddressRequest;
}

const InstitutionAddressDialog: FC<InstitutionAddressDialogProps> = (
  { onClose, open, onUpdate, saveHandler, useLoading, readOnly, title, data }
) => {
  const { t } = useTranslation();
  const loading = useLoading();
  const formId = useId();
  const axiosErrorHandler = useAxiosErrorHandler();

  const defaultValues = {
    dependencyName: "",
    street: "",
    postalCode: "",
    city: "",
    phone: "",
    fax: "",
    email: "",
    website: ""
  };

  const { control, handleSubmit, reset } = useForm({ defaultValues });

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const successHandler = () => {
    showSuccess(t("institutions.dialog.saveSuccess"));
    onClose();
    onUpdate();
  };

  const onSubmit = useEvent((values: AddressRequest) => {
    saveHandler(values).then(successHandler).catch(axiosErrorHandler);
  });

  return (
    <BaseDialog
      className="InstitutionAddressDialog" open={open} onClose={onClose} title={title}
      loading={loading} closeOnBackdropClick={!loading} closeOnEscapeKeyDown={!loading} size="medium"
      actions={(<>
        <Button variant="outlined" size="large" onClick={onClose} disabled={loading}>
          {t(readOnly ? "main.close" : "main.cancel")}
        </Button>
        {!readOnly &&
          <Button variant="contained" size="large" form={formId} type="submit" disabled={loading || !open}>
            {t("main.save")}
          </Button>
        }
      </>)}>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <FormField
          control={control} name="dependencyName" required label={t("institution.addresses.dependencyName")} autoFocus
          fullWidth
        />
        <InstitutionAddressFields readOnly={readOnly} control={control} />
      </form>
    </BaseDialog>
  );
};

export default memo(InstitutionAddressDialog);

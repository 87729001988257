import { Button } from "@mui/material";
import React, { memo, useId } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import { BaseDialog, FormField } from "@nbp/dnafe-material-ui/dist/components";
import { SimpleDialogProps } from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import { RegistrationAuthorityAdminRequest, RegistrationAuthorityAdminResponse } from "../../api";
import {
  createRegistrationAuthorityAdmin,
  useCreateRegistrationAuthorityAdminLoading
} from "../../hooks/registrationAuthority";
import { AxiosError } from "axios";
import { getUserMe } from "../../hooks/user";
import { useCurrentUser } from "../../hooks/auth";
import { useAxiosErrorHandler } from "../../hooks/general";
import { EMAIL_PATTERN } from "../../constants/form";
import { showError, showSuccess } from "@nbp/dnafe-material-ui/dist/hooks/snackbar";

interface RegistrationAuthorityAdminCreateDialogProps extends SimpleDialogProps {
  onUpdate?: () => void;
  registrationAuthorityId: string;
}

const RegistrationAuthorityAdminCreateDialog = (
  { onClose, open, onUpdate, registrationAuthorityId }: RegistrationAuthorityAdminCreateDialogProps
) => {
  const { t } = useTranslation();
  const createRegistrationAuthorityAdminLoading = useCreateRegistrationAuthorityAdminLoading();
  const formId = useId();
  const currentUser = useCurrentUser();
  const axiosErrorHandler = useAxiosErrorHandler();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: ""
    }
  });

  const successHandler = (data: RegistrationAuthorityAdminResponse) => {
    showSuccess(t("registrationAuthority.admin.saveSuccess"));
    onClose();
    onUpdate();

    if (data?.email === currentUser?.decodedToken?.email) {
      getUserMe().catch(axiosErrorHandler);
    }
  };

  const errorHandler = (error: AxiosError) => {
    if (error.response.status === 400) {
      showError(t("registrationAuthority.admin.userNotExistError"));
    } else if (error.response.status === 409) {
      showError(t("registrationAuthority.admin.userAlreadyExistError"));
    } else {
      axiosErrorHandler(error);
    }
  };

  const onSubmit = useEvent(async (values: RegistrationAuthorityAdminRequest) => {
    await createRegistrationAuthorityAdmin({
      args: { registrationAuthorityId },
      body: values
    }).then(successHandler).catch(errorHandler);
  });

  const loading = createRegistrationAuthorityAdminLoading;

  return (
    <BaseDialog
      className="RegistrationAuthorityAdminCreateDialog" open={open} onClose={onClose}
      title={t("registrationAuthority.admin.createTitle")}
      loading={loading} closeOnBackdropClick={!loading} closeOnEscapeKeyDown={!loading} size="medium"
      actions={(<>
        <Button variant="outlined" size="large" onClick={onClose} disabled={loading}>
          {t("main.cancel")}
        </Button>
        <Button variant="contained" size="large" form={formId} type="submit" disabled={loading || !open}>
          {t("main.save")}
        </Button>
      </>)}>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <FormField
          control={control} name="email" required label={t("registrationAuthority.admin.email")}
          autoFocus fullWidth
          rules={{
            required: "required",
            pattern: {
              value: EMAIL_PATTERN
            }
          }}
        />
      </form>
    </BaseDialog>
  );
};

export default memo(RegistrationAuthorityAdminCreateDialog);

import { DataTable } from "@nbp/dnafe-material-ui/dist/components";
import React, { FC, memo, useCallback, useMemo } from "react";
import { InstitutionUserResponse } from "../../api";
import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@mui/material";
import { MemberStatusTemplate } from "./InstitutionMembersTable";
import { SPLIT_MEMBER_COPY, SPLIT_MEMBER_KEEP, SPLIT_MEMBER_MOVE } from "../../constants/institution";

interface InstitutionSplitMembersTableProps {
  data: InstitutionUserResponse[];
  setAction: (id: string, action: string) => void;
}

const InstitutionSplitMembersTable: FC<InstitutionSplitMembersTableProps> = (
  { data = [], setAction }
) => {
  const { t, i18n: { language } } = useTranslation();

  const DataTableActionsColumnTemplate = useCallback((row: InstitutionUserResponse) => (
    <Select onChange={(event: any, node: any) => {
      setAction(row.userId, node.props.value);
    }} defaultValue={SPLIT_MEMBER_KEEP}>
      <MenuItem value={SPLIT_MEMBER_KEEP}>{t("institution.split.action." + SPLIT_MEMBER_KEEP)}</MenuItem>
      <MenuItem value={SPLIT_MEMBER_MOVE}>{t("institution.split.action." + SPLIT_MEMBER_MOVE)}</MenuItem>
      <MenuItem value={SPLIT_MEMBER_COPY}>{t("institution.split.action." + SPLIT_MEMBER_COPY)}</MenuItem>
    </Select>
  ), [language]);

  const columns = useMemo(() => [
    {
      name: "email",
      title: t("institution.member.email")
    },
    {
      name: "approvalState",
      headerClassName: "column-created-at",
      title: t("institution.member.approvalState"),
      template: MemberStatusTemplate
    },
    {
      name: "actions",
      className: "action-column",
      headerClassName: "action-column-header",
      title: t("main.actions"),
      template: DataTableActionsColumnTemplate
    }
  ], [language]);

  return (
    <div className="InstitutionSplitMembersTable">
      <DataTable data={data} columns={columns} emptyText={t("main.empty")} />
    </div>
  );
};

export default memo(InstitutionSplitMembersTable);

import { Button } from "@mui/material";
import React, { FC, memo, useId, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import { BaseDialog, FormField } from "@nbp/dnafe-material-ui/dist/components";
import { SimpleDialogProps } from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import DateRange from "../Layout/Form/DateRange";
import { FORMAT_DATE_SERVER } from "../../constants/date";
import moment from "moment";
import { useAxiosErrorHandler } from "../../hooks/general";
import { EMAIL_PATTERN } from "../../constants/form";
import { AxiosError } from "axios";
import { showError, showSuccess } from "@nbp/dnafe-material-ui/dist/hooks/snackbar";

interface InstitutionMemberCreateDialogProps extends SimpleDialogProps {
  institutionId?: string;
  title?: string;
  readOnly?: boolean;
  onUpdate?: () => void;
  useLoading: () => boolean;
  saveHandler: (values: any) => Promise<any>;
}

interface FormFields {
  email?: string;
  startDate?: string;
  endDate?: string;
}

const InstitutionMemberCreateDialog: FC<InstitutionMemberCreateDialogProps> = (
  { onClose, open, institutionId, onUpdate, saveHandler, useLoading, readOnly, title }
) => {
  const { t } = useTranslation();
  const loading = useLoading();
  const formId = useId();
  const axiosErrorHandler = useAxiosErrorHandler();

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: { email: "", startDate: "", endDate: "" }
  });
  const [formValues, setFormValues] = useState<FormFields>(getValues());

  const updateFormValues = useEvent(() => {
    setFormValues(getValues());
  });

  const successHandler = () => {
    showSuccess(t("institution.member.saveSuccess"));
    onClose();
    onUpdate();
  };

  const errorHandler = (error: AxiosError) => {
    if (error.response?.status === 404) {
      showError(t("institution.member.userNotExistError"));
    } else if (error.response?.status === 409) {
      showError(t("institution.member.memberExistError"));
    } else {
      axiosErrorHandler(error);
    }
  };

  const onSubmit = useEvent(async (values: FormFields) => {
    await saveHandler({
      args: { institutionId },
      body: {
        ...values,
        startDate: values.startDate ? values.startDate + "T00:00:00Z" : undefined,
        endDate: values.endDate ? values.endDate + "T23:59:59Z" : undefined
      }
    }).then(successHandler).catch(errorHandler);
  });

  const dateIsDefined = !!formValues.startDate || !!formValues.endDate;

  return (
    <BaseDialog
      className="InstitutionMemberCreateDialog" open={open} onClose={onClose} title={title}
      loading={loading} closeOnBackdropClick={!loading} closeOnEscapeKeyDown={!loading} size="medium"
      actions={(<>
        <Button variant="outlined" size="large" onClick={onClose} disabled={loading}>
          {t(readOnly ? "main.close" : "main.cancel")}
        </Button>
        {!readOnly &&
          <Button variant="contained" size="large" form={formId} type="submit" disabled={loading || !open}>
            {t("main.save")}
          </Button>
        }
      </>)}>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <FormField
          control={control} name="email" required label={t("institution.member.email")}
          autoFocus fullWidth
          rules={{
            required: "required",
            pattern: {
              value: EMAIL_PATTERN
            }
          }}
        />
        <DateRange
          control={control}
          startDateFieldName="startDate"
          startDateLabel={t("institution.member.startDate")}
          startRequired={dateIsDefined}
          minDate={moment().format(FORMAT_DATE_SERVER)}
          endDateFieldName="endDate"
          endDateLabel={t("institution.member.endDate")}
          endRequired={false}
          getValues={getValues}
          onChange={updateFormValues}
        />
      </form>
    </BaseDialog>
  );
};

export default memo(InstitutionMemberCreateDialog);

import React, { memo } from "react";
import PageHeader from "../components/Layout/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import { PageContent } from "@nbp/dnafe-material-ui/dist/components";
import { usePageTitle } from "../hooks/usePageTitle";
import { useUserMeData } from "../hooks/user";
import { NavLink } from "react-router-dom";
import { LINK_INSTITUTIONS } from "../constants/navigate";
import { getMyAdminInstitution } from "../hooks/institution";

const InstitutionsPage = () => {
  usePageTitle("menu.institutions");
  const { t } = useTranslation();
  const userMe = useUserMeData();
  const { institutionAdmins, institutionIssuers } = userMe ?? {};
  const issuerInstitutions = institutionIssuers?.filter(({ institution: { id } }: any) => {
    return !getMyAdminInstitution(id);
  });

  return (
    <div className="InstitutionsPage">
      <PageHeader title={t("menu.institutions")} />
      <PageContent>
        {!!institutionAdmins?.length && <>
          <h3 className="text-title-2">{t("institutions.adminInstitutionsTitle")}</h3>
          <ul>{institutionAdmins?.map(({ institution: { id, name } }: any) =>
            (<li key={id}><NavLink to={LINK_INSTITUTIONS + "/" + id}>{name}</NavLink></li>)
          )}</ul>
        </>}
        {!!issuerInstitutions?.length && <>
          <h3 className="text-title-2">{t("institutions.issuerInstitutionsTitle")}</h3>
          <ul>{issuerInstitutions?.map(({ institution: { id, name } }: any) =>
            (<li key={id}><NavLink to={LINK_INSTITUTIONS + "/" + id}>{name}</NavLink></li>)
          )}</ul>
        </>}
      </PageContent>
    </div>
  );
};

export default memo(InstitutionsPage);

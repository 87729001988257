import React, { memo } from "react";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { SEARCH_DELAY } from "../../constants/usability";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import { useTimeout } from "@nbp/dnafe-material-ui/dist/hooks/useTimeout";
import "./SearchField.scss";

const InputProps = {
  endAdornment: (
    <InputAdornment position="end"> <SearchIcon /> </InputAdornment>
  )
};

interface SearchFieldProps {
  placeholder: string;
  onSearch: (value: string) => void;
  className?: string;
}

const SearchField = ({ placeholder, onSearch, className }: SearchFieldProps) => {
  const { setTimeout, timeout } = useTimeout();

  const onSearchEvent = useEvent((event: Event) => {
    clearTimeout(timeout);
    setTimeout(() => {
      onSearch((event.target as HTMLInputElement).value);
    }, SEARCH_DELAY);
  });

  return (
    <div className={"SearchField" + (className ? " " + className : "")}>
      <TextField
        size="small" placeholder={placeholder} variant="outlined" onChange={onSearchEvent}
        InputProps={InputProps}
      />
    </div>
  );
};

export default memo(SearchField);
